import { defineStore } from 'pinia'
import api from '@/modules/account/api'

export const usePaymentStore = defineStore('payment', {
  state: () => ({
    paymentDetails: {
      amount: null,
      accountId: null,
      recipientName: '',
      recipientIban: '',
      referenceNumber: '',
      description: '',
      createdTime: null
    },
    paymentStatus: null,
    shouldResetPaymentForm: false
  }),
  actions: {
    resetPaymentDetails () {
      this.paymentDetails = {
        amount: null,
        accountId: null,
        recipientName: '',
        recipientIban: '',
        referenceNumber: '',
        description: '',
        createdTime: null
      }
      this.shouldResetPaymentForm = true
    },
    redirectToPaymentForm () {
      this.paymentStatus = null
    },
    setPaymentStatus (status) {
      this.paymentStatus = status
    },
    updatePaymentDetails (details) {
      this.paymentDetails = {
        ...this.paymentDetails,
        ...details
      }
    },
    setShouldResetPaymentForm (value) {
      this.shouldResetPaymentForm = value
    },
    async fetchPaymentToState (paymentId) {
      const payment = await api.getPaymentById(paymentId)
      await this.updatePaymentDetails(payment)
    }
  }
})
