<template>
  <div>
    <bb-row>
      <bb-col lg="13" md="12" xs="12" class="d-flex flex-column flex-md-row">
        <bb-dropdown
          class="nm-x-15 nm-t-15 nm-x-md-30 nm-t-md-30 m-lg-0"
          :name="dropdownFieldName"
          :disabled="areFilterInputsDisabled && !isAccountFlavor"
        >
          <template #trigger>
            <bb-dropdown-item
              v-if="isValueSelected || hasOnlyOneDropdownItem"
              :button-props="bbDropdownItemHeadProps"
              :class="{
                ...commonDropdownButtonClasses,
                'dropdown-button': true,
                'nm-l-15': isAccountFlavor && !isMobile
              }"
              data-testid="currently-selected-value-button"
            >
              <template v-if="isAccountFlavor">
                <div
                  class="d-inline-block"
                  v-if="isValueSelected"
                >
                  <div
                    data-testid="account-statement__currently-selected-iban"
                    class="f-color-gray-90 f-gotham-book">
                    {{ itemChosen.iban }}
                  </div>
                  <transactions-filter-item-account-flavor
                    :account-type-name="itemChosen.accountTypeName"
                    :status-code="itemChosen.statusCode"
                    :available-balance="itemChosen.availableBalance"
                    :currency-code="itemChosen.currencyCode"
                  />
                </div>
                <span v-else class="f-gotham-book">{{ translations.selectYourAccount }}</span>
              </template>
              <template v-else>
                <bb-icon v-if="itemChosen.icon" :name="itemChosen.icon" fill="original" size="48"/>
                <span class="f-gotham-book p-l-10">{{ itemChosen.type }}</span>
                &nbsp;|&nbsp;{{ itemChosen.digits }}
              </template>
            </bb-dropdown-item>
            <bb-dropdown-item
              v-else
              :button-props="bbDropdownItemHeadProps"
              :class="{
                ...commonDropdownButtonClasses,
                'f-gotham-book': true,
                'p-y-25': !isAccountFlavor,
                'nm-l-15': isAccountFlavor && !isMobile
              }"
              data-testid="select-all-items-button"
              :label="translationAllItems"
            />
          </template>
          <bb-dropdown-item
            @click="() => onChange(null)"
            v-if="isValueSelected && dropdownItems.length > 1 && !isAccountFlavor"
            :class="{
              ...commonDropdownButtonClasses,
              'f-gotham-book': true,
              'p-y-25': !isAccountFlavor,
            }"
            :label="translations.allCards"
          />
          <div v-if="dropdownItems.length > 1 || isAccountFlavor">
            <bb-dropdown-item
              @click="() => onChange(item.id)"
              v-for="(item) in dropdownItems"
              :key="item.id"
              :class="{
                ...commonDropdownButtonClasses,
                'bg-gray-10': item.id === value[dropdownFieldName]
              }"
            >
              <template v-if="isAccountFlavor">
                <div class="f-gotham-medium f-color-gray-90">
                  {{ item.iban }}
                </div>
                <transactions-filter-item-account-flavor
                  :account-type-name="item.accountTypeName"
                  :status-code="item.statusCode"
                  :available-balance="item.availableBalance"
                  :currency-code="item.currencyCode"
                />
              </template>
              <template v-else>
                <bb-icon v-if="item.icon" :name="item.icon" fill="original" size="48"/>
                <span class="f-gotham-book p-l-10">{{ item.type }}</span>
                &nbsp;|&nbsp;{{ item.digits }}
              </template>
            </bb-dropdown-item>
          </div>
        </bb-dropdown>
        <bb-separator class="hidden-min-lg border-gray-20 nm-x-15 nm-x-md-30 m-b-5 w-auto"/>
      </bb-col>
      <bb-col>
        <div class="d-flex align-items-flex-start h-100 p-b-10">
          <bb-datepicker
            name="startDate"
            v-bind="datePickerComponentsProps"
            v-model="val.startDate"
            v-validate="'required'"
            :data-vv-as="translations.startDate"
            :disabled="areFilterInputsDisabled"
            :lang="datepickerLanguage"
            @change="() => onDatePickerIntervalChange(val.startDate, val.endDate)"
          />
          <div class="m-t-30 m-x-10"><bb-separator width="2" style="width: 20px" class="border-gray-30"/></div>
          <bb-datepicker
            name="endDate"
            v-bind="datePickerComponentsProps"
            v-model="val.endDate"
            :disabled="areFilterInputsDisabled"
            :lang="datepickerLanguage"
            @change="() => onDatePickerIntervalChange(val.startDate, val.endDate)"
            v-validate="endDateRules"
            :data-vv-as="translations.endDate"
          />
        </div>
      </bb-col>
    </bb-row>
    <bb-row v-if="isCreditCardDropdownShown || isContractDropdownShown" class="m-y-15 m-y-sm-20">
      <bb-col v-if="isContractDropdownShown" col="24" md="12">
        <div class="m-b-15 color-gray f-gotham-medium f-small">{{ translations.contractNumber }}</div>
        <transactions-filter-contract-select
          v-model="val.contractId"
          type="contract"
          :isLoading="isLoading"
          :options="contractOptions"
        />
      </bb-col>
      <bb-col v-if="isCreditCardDropdownShown" col="24" md="12" class="m-t-15 m-t-md-0">
        <div class="m-b-15 color-gray f-gotham-medium f-small">{{ translations.cardNumber }}</div>
        <transactions-filter-contract-select
          v-model="val.cardId"
          type="card"
          :isLoading="isLoading"
          :options="cardOptions"
        />
      </bb-col>
    </bb-row>
  </div>
</template>

<script>
import { isNull } from 'lodash'
import { mapState } from 'pinia'
import dayjs from 'dayjs'
import { useRootStore } from '../../store/root'
import TransactionsFilterItemAccountFlavor from './TransactionsFilterItemAccountFlavor.vue'
import TransactionsFilterContractSelect from './TransactionsFilterContractSelect.vue'

export default {
  name: 'main-transaction-filter',
  inject: ['$validator'],
  components: {
    TransactionsFilterItemAccountFlavor,
    TransactionsFilterContractSelect
  },
  props: {
    value: { // used with v-model
      type: Object,
      required: true
    },
    areFilterInputsDisabled: {
      type: Boolean,
      default: false
    },
    flavor: {
      type: String,
      validator (value) {
        return ['account', 'credit-card'].includes(value)
      }
    },
    dropdownItems: {
      required: true,
      default: () => ([])
    },
    datePickerMinDate: {
      type: Date,
      required: true
    },
    datePickerMaxDate: {
      type: Date,
      required: true
    },
    datePickerMaxRange: {
      type: Number,
      required: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      val: this.value || {
        // cannot use computed property here, dropdownFieldName copy-paste :(
        [this.flavor === 'account' ? 'accountId' : 'cardId']: null,
        startDate: null,
        endDate: null,
        contractId: null,
        cardId: null
      }
    }
  },
  watch: {
    value (newVal) {
      this.val = newVal
    }
  },
  methods: {
    onInput () {
      this.$validator.validate().then((valid) => {
        this.$emit('isValid', valid)
      })
      this.$emit('input', this.val)
    },
    onChange (id) {
      this.val[this.dropdownFieldName] = id
      this.onInput()
      this.$emit('on-item-change', id)
    },
    onDatePickerIntervalChange () {
      this.onInput()
      this.$emit('on-date-picker-interval-change', this.val.startDate, this.val.endDate)
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'datepickerLanguage', 'locale', 'featureFlags']),
    commonDropdownButtonClasses () {
      return {
        'f-default': true,
        'mob-f-default': true,
        'p-x-15': true,
        'p-x-md-30': true,
        'p-x-lg-15': true
      }
    },
    isCreditCardDropdownShown () {
      return this.featureFlags.enableAccountStatementCreditCardAdvancedFilter && this.isAccountFlavor && this.cardOptions.length > 0
    },
    isContractDropdownShown () {
      return this.featureFlags.enableAccountStatementContractNumberAdvancedFilter && this.isAccountFlavor && this.contractOptions.length > 0
    },
    cardOptions () {
      return this.selectedAccount?.cards || []
    },
    contractOptions () {
      return this.selectedAccount?.contracts || []
    },
    selectedAccount () {
      return this.dropdownItems.find(account => account.id === this.val.accountId)
    },
    isValueSelected () {
      return this.value[this.dropdownFieldName] !== null
    },
    translationAllItems () {
      return this.isAccountFlavor
        ? this.translations.selectYourAccount
        : this.translations.allCards
    },
    translations () {
      return {
        startDate: this.$pgettext('transaction_filter', 'Start date'),
        endDate: this.$pgettext('transaction_filter', 'End date'),
        endDateGreaterThanStartDate: this.$pgettext('transaction_filter', 'End date should be greater than start date'),
        selectYourAccount: this.$pgettext('EDB', 'Select your account'),
        allCards: this.$pgettext('credit_card_transactions_search', 'All cards'),
        maxPeriodError: this.$pgettext('transaction_filter', 'Maximum date range is % years'),
        cardNumber: this.$pgettext('card_transactions_search', 'Card number'),
        contractNumber: this.$pgettext('card_transactions_search', 'Contract number')
      }
    },
    bbDropdownItemHeadProps () {
      if (this.isAccountFlavor) {
        return {
          iconAfter: 'ui-chevron-down'
        }
      }

      return {
        iconAfter: this.hasOnlyOneDropdownItem ? null : 'ui-chevron-down'
      }
    },
    hasOnlyOneDropdownItem () {
      return this.dropdownItems.length === 1
    },
    itemChosen () {
      if (this.hasOnlyOneDropdownItem && !this.isAccountFlavor) {
        return this.dropdownItems[0]
      }

      if (isNull(this.val[this.dropdownFieldName])) {
        return null
      }

      return this.dropdownItems.find((item) => item.id === this.value[this.dropdownFieldName]) ?? null
    },
    dropdownFieldName () {
      return this.isAccountFlavor ? 'accountId' : 'cardId'
    },
    isAccountFlavor () {
      return this.flavor === 'account'
    },
    datePickerComponentsProps () {
      return {
        format: 'DD.MM.YYYY',
        minDate: this.datePickerMinDate,
        maxDate: this.datePickerMaxDate,
        shortcuts: false,
        framed: true,
        name: 'secondary-datepicker',
        class: 'flex-fill'
      }
    },
    endDateRules () {
      return {
        required: true,
        maximum_period: this.datePickerMaxRange,
        end_date_greater_than_start_date: this.val.startDate
      }
    }
  },
  created () {
    this.$validator.extend('maximum_period', {
      getMessage: (_field, _args, data) => {
        return data.message
      },
      validate: (_value, args) => {
        let isValid = false
        const startDate = this.val.startDate
        const endDate = this.val.endDate

        if (!startDate || !endDate || !args[0]) {
          isValid = true
        } else {
          const diff = dayjs(endDate).diff(dayjs(startDate), 'years', true)
          isValid = diff < args[0]
        }

        return Promise.resolve({
          valid: isValid,
          data: {
            message: this.translations.maxPeriodError.replace('%', args[0])
          }
        })
      }
    })
    this.$validator.extend('end_date_greater_than_start_date', {
      getMessage: (_field, _args, data) => {
        return this.translations.endDateGreaterThanStartDate
      },
      validate: (_value, args) => {
        if (!this.val.startDate || !this.val.endDate || dayjs(this.val.endDate).isSame(dayjs(this.val.startDate))) {
          return Promise.resolve({
            valid: true
          })
        }

        return Promise.resolve({
          valid: dayjs(this.val.endDate).isAfter(dayjs(this.val.startDate))
        })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
::v-deep button.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
