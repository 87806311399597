<template>
  <div class="setting-buttons">
    <template v-if="minimal">
      <div class="setting-buttons__title">{{translations.title}}</div>
      <div class="setting-buttons__list">
        <settings-button v-for="(button, i) in buttons" :key="i" v-bind="button"/>
      </div>
    </template>
    <bb-accordion :value="accordions" name="card-settings-accordion" card v-else>
      <template slot="accordion">
        <div class="setting-buttons__list">
          <settings-button
            v-for="(button, i) in buttons"
            :key="i"
            v-bind="button"
          />
        </div>
      </template>
    </bb-accordion>
  </div>
</template>

<script>
import SettingsButton from './SettingsButton.vue'
import '@bigbank/interface-components/dist/svg/ui/eye'
import '@bigbank/interface-components/dist/svg/ui/file-document-box-outline'
import '@bigbank/interface-components/dist/svg/ui/file-document-outline'
import '@bigbank/interface-components/dist/svg/ui/cancel'
import { CREDIT_CARD } from '@/const'
import { CardStatus, CardType } from '@bigbank/dc-common/clients/http/credit-card/credit-card.enums'
import { mapState } from 'pinia'
import { useRootStore } from '../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'
import { useCreditCardStore } from '@/store/modules/credit-card'

export default {
  components: {
    SettingsButton
  },
  props: {
    card: {
      required: true
    },
    minimal: {
      default: false
    }
  },
  computed: {
    ...mapState(useCreditCardStore, ['areCreditCardButtonsEnabled']),
    ...mapState(useLoanStore, {
      loanFeatureFlags: 'featureFlags'
    }),
    ...mapState(useRootStore, ['featureFlags']),
    translations () {
      return {
        title: this.$pgettext('credit_card_card_view', 'Card settings'),
        showPinCode: this.$pgettext('credit_card_card_view', 'Show PIN code'),
        showCardDetails: this.$pgettext('credit_card_card_view', 'Show card details'),
        viewCardContract: this.$pgettext('credit_card_card_view', 'View card contract'),
        blockOrCloseCard: this.$pgettext('credit_card_card_view', 'Block/Close the card'),
        unblockCard: this.$pgettext('credit_card_card_view', 'Unblock the card')
      }
    },
    accordions () {
      return [{
        id: 'accordion',
        title: this.translations.title,
        open: false
      }]
    },
    isCreditCardActive () {
      return [CardStatus.ACTIVE].includes(this.card.status)
    },
    isCreditCardBlocked () {
      return [CardStatus.BLOCKED].includes(this.card.status)
    },
    isCreditCardClosed () {
      return this.card.status === CardStatus.CLOSED
    },
    isCreditCardPhysicalAndVirtual () {
      return this.card.type === CardType.PHYSICAL_AND_VIRTUAL
    },
    buttons () {
      return [{
        icon: 'ui-eye',
        text: this.$pgettext('credit_card_card_view', 'Show PIN code'),
        name: 'show-pin-code',
        disabled: !this.isButtonEnabled(CREDIT_CARD.SETTINGS_BUTTON_TYPE.SHOW_PIN),
        onClick: async () => this.$emit('onShowPinClick')
      }, {
        icon: 'ui-file-document-box-outline',
        name: 'show-card-details',
        text: this.$pgettext('credit_card_card_view', 'Show card details'),
        disabled: !this.isButtonEnabled(CREDIT_CARD.SETTINGS_BUTTON_TYPE.SHOW_DETAILS),
        onClick: async () => this.$emit('onShowPanAndCvvClick')
      }, {
        icon: 'ui-file-document-outline',
        text: this.$pgettext('credit_card_card_view', 'Manage Card'),
        name: 'view-card-contract',
        disabled: !this.isButtonEnabled(CREDIT_CARD.SETTINGS_BUTTON_TYPE.MANAGE_CARD),
        onClick: () => {
          this.$router.push({
            name: 'credit-card-details',
            params: { cardId: this.card.id }
          })
        },
        visible: this.featureFlags.showCreditCardContractButton
      }, {
        icon: 'ui-cancel',
        name: this.isCreditCardActive ? 'block-or-close-card' : 'unblock-card',
        text: this.translations[this.isCreditCardActive || !this.isCreditCardBlocked ? 'blockOrCloseCard' : 'unblockCard'],
        disabled: !this.isButtonEnabled(CREDIT_CARD.SETTINGS_BUTTON_TYPE.BLOCK_OR_UNBLOCK),
        onClick: async () => {
          if (this.isCreditCardActive) {
            this.$emit('onBlockCardClick')
          } else if (this.isCreditCardBlocked) {
            this.$emit('onUnblockCardClick')
          }
        },
        visible: this.loanFeatureFlags.enableCreditCardBlocking
      }]
    }
  },
  methods: {
    isButtonEnabled (type) {
      if (!this.areCreditCardButtonsEnabled) {
        return false
      }

      switch (type) {
        case CREDIT_CARD.SETTINGS_BUTTON_TYPE.SHOW_PIN:
          return this.isCreditCardPhysicalAndVirtual && this.isCreditCardActive
        case CREDIT_CARD.SETTINGS_BUTTON_TYPE.SHOW_DETAILS:
          return this.card.status === CardStatus.ACTIVE
        case CREDIT_CARD.SETTINGS_BUTTON_TYPE.BLOCK_OR_UNBLOCK:
          return [CardStatus.ACTIVE, CardStatus.BLOCKED].includes(this.card.status)
        case CREDIT_CARD.SETTINGS_BUTTON_TYPE.MANAGE_CARD:
        default:
          return true
      }
    }
  }
}
</script>

<style lang="scss">
.setting-buttons {
  font-size: $font-size-small;

  .bb-accordion-item--card {
    background: $white;
  }

  .bb-accordion-item__header-title,
  &__title {
    color: $gray;
    font-family: $gotham-medium;
    font-size: $default-font-size;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > * {
      margin-top: 2px;
      margin-bottom: 2px;
      justify-content: flex-start;
    }

    @media (min-width: $desktop-view-breaking-point) {
      flex-flow: row wrap;

      > * {
        width: 50%;
      }
    }
  }
}
</style>
