<template>
  <div>
    <template v-if="isClosedTagOnAccountStatementPageEnabled">
      <template v-if="isActive">
        <div class="f-gotham-book f-smallest f-color-gray-90">
          {{ getAccountTypeName(accountTypeName) }} - <span class="f-color-mint f-gotham-bold">
            {{ formatMoneyWithCurrency(availableBalance || 0, currencyCode, locale) }}
          </span>
        </div>
      </template>
      <template v-else>
        <div class="f-gotham-book f-smallest f-color-gray-90">
          {{ getAccountTypeName(accountTypeName) }}
          <bb-label
            :value="translations.accountStatus.CLOSED"
            size="font"
            class="h-100 bg-red p-y-1 m-x-3 border-radius-lg f-tiniest"
            data-testid="closed-account-tag"
          />
        </div>
      </template>
    </template>

    <template v-else>
      <div class="f-gotham-book f-smallest f-color-gray-90">
        {{ getAccountTypeName(accountTypeName) }} - <span class="f-color-mint f-gotham-bold">
          {{ formatMoneyWithCurrency(availableBalance || 0, currencyCode, locale) }}
        </span>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState } from 'pinia'
import { useRootStore } from '../../store/root'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { AccountStatusCode } from '@bigbank/dc-common/clients/http/account/account.enums'

export default {
  name: 'transactions-filter-item-account-flavor',
  props: {
    accountTypeName: {
      type: String,
      required: true
    },
    statusCode: {
      type: String,
      required: true
    },
    availableBalance: {
      type: Number,
      default: 0
    },
    currencyCode: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'featureFlags']),
    translations () {
      return {
        accountTypeName: {
          'Client account': this.$pgettext('EDB', 'Client account'),
          'Term deposit': this.$pgettext('EDB', 'Term deposit'),
          'Savings deposit': this.$pgettext('EDB', 'Savings deposit'),
          'Current account': this.$pgettext('EDB', 'Current account')
        },
        accountStatus: {
          CLOSED: this.$pgettext('EDB_account_status', 'Closed')
        }
      }
    },
    isClosedTagOnAccountStatementPageEnabled () {
      return this.featureFlags.enableClosedTagOnAccountStatementPage
    },
    isActive () {
      return this.statusCode !== AccountStatusCode.Closed
    }
  },
  methods: {
    formatMoneyWithCurrency,
    getAccountTypeName (accountTypeName) {
      return this.translations.accountTypeName[accountTypeName] ?? accountTypeName
    }
  }
}
</script>
