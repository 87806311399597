<template>
  <bb-box thin class="limit-box bg-gray-10">
    <bb-row class="m-b-10">
      <bb-col>
        <p class="f-small" :class="isRestricted ? 'f-color-gray-70' : 'f-color-navy'" style="display: inline-flex;">{{ accountNumber }}</p>
      </bb-col>
      <bb-col col="auto">
        <bb-label v-if="isRestricted" :value="translations.restricted" class="bg-red limit-box__label f-tiniest" size="18" data-testid="limit-box-label" />
        <bb-label v-if="isSeized" :value="translations.seized" class="bg-red limit-box__label f-tiniest" size="18" data-testid="limit-box-label" />
      </bb-col>
    </bb-row>
    <div class="m-y-10">
      <p class="f-tiny f-color-gray-70 f-case-upper">{{ translations.dailyLimit }}</p>
      <p class="f-color-gray f-strong m-b-5">{{ formatNumber(dailyRawLimit) }}</p>
      <limit-box-progress
        v-bind="dailyLimitProgressProps"
      />
    </div>
    <div class="m-y-10">
      <p class="f-tiny f-color-gray-70 f-case-upper">{{ translations.monthlyLimit }}</p>
      <p class="f-color-gray f-strong m-b-5">{{ formatNumber(monthlyRawLimit) }}</p>
      <limit-box-progress
        v-bind="monthlyLimitProgressProps"
      />
    </div>
    <div v-if="featureFlags.enableAccountsEditLimits" class="m-t-20 m-b-5">
      <bb-button
        :label="translations.editLimits"
        data-testid="edit-limits-button"
        size="sm"
        display="block"
        inverted
        :disabled="isRestricted || isSeized"
        @click="openEditLimits"
      />
    </div>
    <edit-limit-modal
      :visible="isEditLimitModalOpen"
      :accountNumber="accountNumber"
      :draftDailyLimit="draftDailyLimit"
      :draftMonthlyLimit="draftMonthlyLimit"
      :dailyLimitProgressProps="dailyLimitProgressProps"
      :monthlyLimitProgressProps="monthlyLimitProgressProps"
      :maximumAccountLimit="maximumAccountLimit"
      :isRestricted="isRestricted"
      :isSeized="isSeized"
      @close="closeEditLimits"
      @goToEditSummary="toggleEditLimitsSummary"
    />
    <edit-limit-summary-modal
      :visible="isEditLimitSummaryModalOpen"
      :accountId="accountId"
      :accountNumber="accountNumber"
      :requestedMonthlyLimit="draftMonthlyLimit"
      :requestedDailyLimit="draftDailyLimit"
      :previousDailyLimit="dailyRawLimit"
      :previousMonthlyLimit="monthlyRawLimit"
      :maximumAccountLimit="maximumAccountLimit"
      :moneyPurposeAnswer="moneyPurposeAnswer"
      :moneyOriginAnswer="moneyOriginAnswer"
      @close="closeEditLimits"
      @goBackToEdit="toggleEditLimitsSummary"
      @accountLimitsChangeSigned="refreshLimits"
    />
  </bb-box>
</template>

<script>
import { mapState } from 'pinia'
import { useRootStore } from '@/store/root'
import { formatMoneyWithCurrency, formatNumberToLocale } from '@/plugins/numformat'
import { BbLabel } from '@bigbank/interface-components'
import LimitBoxProgress from './LimitBoxProgress.vue'
import EditLimitModal from './EditLimitModal.vue'
import EditLimitSummaryModal from '@account/components/EditLimitSummaryModal.vue'

export default {
  name: 'limit-box',
  data () {
    return {
      loading: false,
      editVisible: false,
      editSummaryVisible: false,
      draftDailyLimit: 0,
      draftMonthlyLimit: 0,
      moneyPurposeAnswer: '',
      moneyOriginAnswer: ''
    }
  },
  components: {
    BbLabel,
    LimitBoxProgress,
    EditLimitModal,
    EditLimitSummaryModal
  },
  props: {
    accountId: {
      type: Number,
      required: true
    },
    accountNumber: {
      type: String,
      required: true
    },
    dailyRawLimit: {
      type: Number,
      required: true
    },
    dailyRemainingLimit: {
      type: Number,
      required: true
    },
    monthlyRawLimit: {
      type: Number,
      required: true
    },
    monthlyRemainingLimit: {
      type: Number,
      required: true
    },
    maximumAccountLimit: {
      type: Number,
      required: true
    },
    isRestricted: {
      type: Boolean,
      required: true
    },
    isSeized: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(useRootStore, ['currency', 'locale', 'featureFlags']),
    dailyLimitSpent () {
      return this.dailyRawLimit - this.dailyRemainingLimit
    },
    monthlyLimitSpent () {
      return this.monthlyRawLimit - this.monthlyRemainingLimit
    },
    isDailyLimitExceeded () {
      return this.dailyRemainingLimit <= 0
    },
    isMonthlyLimitExceeded () {
      return this.monthlyRemainingLimit <= 0
    },
    translations () {
      return {
        restricted: this.$pgettext('EDB_limits', 'Restricted'),
        seized: this.$pgettext('EDB_limits', 'Seized'),
        dailyLimit: this.$pgettext('EDB_limits', 'Daily limit'),
        monthlyLimit: this.$pgettext('EDB_limits', 'Monthly limit'),
        dailyLimitSpent: this.$gettextInterpolate(this.$pgettext('EDB_limits', '%{amount} spent'), {
          amount: this.formatAmount(this.dailyLimitSpent)
        }),
        dailyLimitLeft: this.$gettextInterpolate(this.$pgettext('EDB_limits', '%{amount} left'), {
          amount: this.formatAmount(this.dailyRemainingLimit)
        }),
        currentDailyTransfers: this.$pgettext('EDB_limits', 'Current daily transfers'),
        currentMonthlyTransfers: this.$pgettext('EDB_limits', 'Current monthly transfers'),
        monthlyLimitSpent: this.$gettextInterpolate(this.$pgettext('EDB_limits', '%{amount} spent'), {
          amount: this.formatAmount(this.monthlyLimitSpent)
        }),
        monthlyLimitLeft: this.$gettextInterpolate(this.$pgettext('EDB_limits', '%{amount} left'), {
          amount: this.formatAmount(this.monthlyRemainingLimit)
        }),
        editLimits: this.$pgettext('EDB_limits', 'Edit limits')
      }
    },
    dailyLimitProgressProps () {
      return {
        type: 'daily',
        title: this.translations.currentDailyTransfers,
        isLimitExceeded: this.isDailyLimitExceeded,
        limitSpent: this.dailyLimitSpent,
        limitRaw: this.dailyRawLimit,
        limitSpentText: this.translations.dailyLimitSpent,
        limitLeftText: this.translations.dailyLimitLeft
      }
    },
    monthlyLimitProgressProps () {
      return {
        type: 'monthly',
        title: this.translations.currentMonthlyTransfers,
        isLimitExceeded: this.isMonthlyLimitExceeded,
        limitSpent: this.monthlyLimitSpent,
        limitRaw: this.monthlyRawLimit,
        limitSpentText: this.translations.monthlyLimitSpent,
        limitLeftText: this.translations.monthlyLimitLeft
      }
    },
    isEditLimitModalOpen () {
      return this.editVisible && !this.editSummaryVisible
    },
    isEditLimitSummaryModalOpen () {
      return this.editVisible && this.editSummaryVisible
    }
  },
  methods: {
    formatNumber (number) {
      return formatMoneyWithCurrency(number, this.currency, 'et', { fractionDigits: 0 })
    },
    formatAmount (limit) {
      return formatMoneyWithCurrency(limit, this.currency, this.locale)
    },
    openEditLimits () {
      this.resetEditLimitsFormData()
      this.editVisible = true
    },
    resetEditLimitsFormData () {
      this.draftDailyLimit = this.dailyRawLimit
      this.draftMonthlyLimit = this.monthlyRawLimit
      this.moneyPurposeAnswer = ''
      this.moneyOriginAnswer = ''
    },
    closeEditLimits () {
      this.editVisible = false
      this.editSummaryVisible = false
    },
    toggleEditLimitsSummary (newLimits) {
      if (newLimits != null) {
        this.draftDailyLimit = newLimits.dailyLimit
        this.draftMonthlyLimit = newLimits.monthlyLimit
        this.moneyPurposeAnswer = newLimits.moneyPurposeAnswer
        this.moneyOriginAnswer = newLimits.moneyOriginAnswer
      }
      this.editSummaryVisible = !this.editSummaryVisible
    },
    refreshLimits () {
      this.$emit('accountLimitsChanged')
    }
  }
}
</script>
<style lang="scss" scoped>
.limit-box__label {
  vertical-align: middle;
}
</style>
