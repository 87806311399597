import { render, staticRenderFns } from "./LimitBox.vue?vue&type=template&id=3f2363ee&scoped=true"
import script from "./LimitBox.vue?vue&type=script&lang=js"
export * from "./LimitBox.vue?vue&type=script&lang=js"
import style0 from "./LimitBox.vue?vue&type=style&index=0&id=3f2363ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f2363ee",
  null
  
)

export default component.exports