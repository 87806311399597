import VueRouter from 'vue-router'
import Errors from './ErrorsView'
import ConfirmEmail from './ConfirmEmail'
import ResetPassword from './ResetPassword'
import Terms from './TermsAndConditions'
import Logout from './LogoutView'
import Landing from './LandingPage'
import { useRootStore } from '../store/root'
import { depositViews, accountViews, loanViews, creditCardViews } from '@/modules'
import onboard from './onboard'
import { translationPicker } from '@/plugins/translations'
import { RouteNames } from '../modules/loan/views/const'
import { featureRouteGuard } from '@/plugins/routeGuards'
import PageAccessControlMiddleware from '@/middlewares/page-access-control.middleware'
import AuthCheckMiddleware from '@/middlewares/auth-check.middleware'

const Deposits = () => import('./DepositsView')
const Account = () => import('./Account')
const Loan = () => import('./Loan')
const CreditCard = () => import('./CreditCard')

const URL_CREDIT_CARD = '/my-credit-card'
const URL_MY_ACTIVE_LOANS = '/loans'
const URL_MY_DEPOSITS = '/my-deposits'
const URL_MY_ACCOUNT = '/account'
const URL_DASHBOARD = '/dashboard'

const routes = [
  {
    path: '/',
    component: Landing,
    meta: {
      showNavbar: true,
      pinNavbar: true,
      guarded: true
    },
    beforeEnter: (to, from, next) => {
      if (to.query.hint === 'switch-user') {
        window.location.href = '/' // refresh page after switching user (reloads feature flags properly)
      }

      onboard(next, {
        URL_MY_ACCOUNT,
        URL_MY_ACTIVE_LOANS,
        URL_MY_DEPOSITS,
        URL_DASHBOARD
      })
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
    beforeEnter: (_to, _from, next) => {
      const rootStore = useRootStore()
      const { isLoggedIn, areTermsAccepted } = rootStore

      if (!areTermsAccepted && isLoggedIn) {
        next()
      } else {
        next('/')
      }
    },
    meta: {
      guarded: false
    }
  },
  // Leave it for now for old links to work. It redirects to contract module
  {
    path: '/my-loans/:page?',
    component: null,
    beforeEnter: (to, from, next) => {
      return next(URL_MY_ACTIVE_LOANS)
    }
  },
  {
    path: '/logout',
    component: Logout,
    meta: {
      guarded: false
    }
  },
  {
    path: '/oops/:errorCode',
    component: Errors,
    meta: {
      showNavbar: true,
      guarded: false
    },
    beforeEnter: (to, from, next) => {
      const rootStore = useRootStore()
      if (rootStore.isLoggedIn) {
        to.meta.showNavbar = false
      }
      next()
    }
  },
  {
    path: '/home',
    beforeEnter: () => {
      const rootStore = useRootStore()
      window.location = translationPicker(
        rootStore.channelConfig.homeUrl,
        rootStore.language
      )
    },
    meta: {
      guarded: false
    }
  },
  {
    path: URL_MY_ACTIVE_LOANS,
    component: Loan,
    meta: {
      showMenu: true,
      guarded: true,
      pinNavbar: true
    },
    children: loanViews,
    beforeEnter: async (to, from, next) => {
      const rootStore = useRootStore()

      if (!rootStore.featureFlags.myActiveLoans) {
        return next('/oops/404')
      }

      if (rootStore.hasCountryStoppedIssuingLoans && to.name !== RouteNames.APPLY_THROUGH_PARTNER) {
        const contractsCount = await rootStore.getContractsCount()
        const isCustomerWithoutLoans = contractsCount?.total === 0

        if (isCustomerWithoutLoans) {
          return next(URL_MY_DEPOSITS)
        }
      }

      return next()
    }
  },
  {
    path: URL_CREDIT_CARD,
    component: CreditCard,
    meta: {
      showMenu: true,
      guarded: true,
      pinNavbar: true,
      meta: {
        analytics: {
          product: 'credit-card'
        }
      }
    },
    beforeEnter: featureRouteGuard('enableCreditCardProduct'),
    children: creditCardViews
  },
  {
    path: URL_MY_DEPOSITS,
    component: Deposits,
    meta: {
      showMenu: true,
      guarded: true,
      pinNavbar: true
    },
    children: depositViews,
    beforeEnter: (to, from, next) => {
      const rootStore = useRootStore()

      if (!rootStore.featureFlags.myDeposits) {
        return next('/oops/404')
      }

      return next()
    }
  },
  {
    path: URL_MY_ACCOUNT,
    component: Account,
    children: accountViews
  },
  {
    path: '/help',
    beforeEnter: (to, from, next) => {
      const rootStore = useRootStore()

      if (rootStore.channelConfig.helpUrl) {
        window.location = translationPicker(rootStore.channelConfig.helpUrl, rootStore.language)
      } else {
        next('/')
      }
    },
    meta: {
      guarded: false
    }
  },
  {
    path: '/confirm-email/:confirmRequestId',
    meta: {
      guarded: false
    },
    component: ConfirmEmail
  },
  {
    path: '/login/reset-password',
    meta: {
      guarded: false
    },
    component: ResetPassword,
    props: route => {
      return ({ token: route.query.token })
    }
  },
  {
    path: '/*',
    beforeEnter: (to, from, next) => {
      next('/oops/404')
    },
    meta: {
      guarded: false
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach(AuthCheckMiddleware)
router.beforeEach(PageAccessControlMiddleware)

router.afterEach(() => {
  const rootStore = useRootStore()
  rootStore.hideSideMenu()
})

const originalPush = VueRouter.prototype.push
const NavigationFailureType = {
  REDIRECTED: 2,
  ABORTED: 4,
  CANCELLED: 8,
  DUPLICATED: 16
}
const errorsToIgnore = [
  NavigationFailureType.REDIRECTED,
  NavigationFailureType.ABORTED,
  NavigationFailureType.CANCELLED,
  NavigationFailureType.DUPLICATED
]

VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => {
    if (!err ||
      errorsToIgnore.includes(err.type) ||
      err.message.includes('Redirected when going from')
    ) {
      return
    }
    throw err
  })
}

export default router
