const AccountRouteName = Object.freeze({
  PersonalData: 'account/personal-data',
  Documents: 'account/documents',
  DocumentsUpload: 'account/documents/upload',
  AccountData: 'account/data',
  AccountVerification: 'account/verification',
  ConfirmData: 'account/confirm',
  TaxReports: 'account/tax-report',
  Payments: 'payments',
  PredefinedPayments: 'payments/predefined',
  Dashboard: 'dashboard',
  MyTransactions: 'my-transactions',
  OpenCurrentAccount: 'open-current-account',
  AccountsLimits: 'limits'
})

const AccountLimitsChangeStatus = Object.freeze({
  AutoApproved: 'AUTO_APPROVED',
  Pending: 'PENDING'
})

const AccountMoneyPurposeAnswerMaxLength = 250
const AccountMoneyOriginAnswerMaxLength = 250

export {
  AccountRouteName,
  AccountLimitsChangeStatus,
  AccountMoneyPurposeAnswerMaxLength,
  AccountMoneyOriginAnswerMaxLength
}
