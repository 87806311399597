<template>
  <bb-modal v-bind="modalProps" data-testid="current-account-modal" @close="onCancel">
    <template slot="heading">
      <div class="header">{{ translations.modalTitle }}</div>
    </template>
    <div class="body">
      <bb-icon class="icon" name="illustration-exclamation-mark" size="fluid" fill="mint-70" />
      <div class="m-t-15 text f-small">{{ translations.modalText }} <span class="f-medium">{{ translations.modalTextAccent }}</span></div>
      <div class="text f-small">{{ translations.listTitle }}</div>
      <ul class="m-t-20">
        <li
          v-for="(point, index) in translations.listPoints"
          :key="index"
          class="current-account-modal__list-item m-b-10"
        >
          <bb-icon name="ui-check-circle-outline" size="24" fill="mint" />
          <p class="m-l-10 m-t-3 f-small text"><span class="f-medium">{{ point.accent }}</span> {{ point.text }}</p>
        </li>
      </ul>
      <div class="m-t-20 text f-small current-account-modal__continue-text" v-sanitize.basic="translations.continueText" />
    </div>
    <div slot="footer" class="modal-footer">
      <div class="logout-wrapper">
        <bb-button v-bind="cancelButtonProps" @click="onCancel" data-testid="skip-current-account-button" class="p-x-5"/>
      </div>
      <div class="continue-wrapper">
        <bb-button v-bind="continueButtonProps" @click="onContinue" data-testid="create-current-account-button"/>
      </div>
    </div>
  </bb-modal>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import LogoutReason from '@/types/logout-reason'
import '@bigbank/interface-components/dist/svg/illustration/money'
import { translationPicker } from '@/plugins/translations'
import { useRootStore } from '../store/root'
import api from '@/api'

export default {
  data () {
    return {
      areActionButtonsDisabled: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'language', 'channelConfig', 'canSkipCurrentAccountFlow']),
    translations () {
      const translations = {
        modalTitle: this.$pgettext('open_current_account_modal', 'Important update!'),
        modalText: this.$pgettext('open_current_account_modal', 'Bigbank is upgrading loan and deposit accounts to'),
        modalTextAccent: this.$pgettext('open_current_account_modal', 'current accounts.'),
        listTitle: this.$pgettext('open_current_account_modal', 'The current account is completely free and comes with several advantages:'),
        listPoints: [
          {
            accent: this.$pgettext('open_current_account_modal', 'Secure storage for your money'),
            text: this.$pgettext('open_current_account_modal', '(guaranteed by the National Guarantee Fund).')
          },
          {
            accent: this.$pgettext('open_current_account_modal', 'Earn 2% interest'),
            text: this.$pgettext('open_current_account_modal', 'on your account balance.')
          },
          {
            accent: this.$pgettext('open_current_account_modal', 'Fast and simple account opening'),
            text: this.$pgettext('open_current_account_modal', '- takes about 1-2 minutes.')
          }
        ],
        continue: this.$pgettext('open_current_account_modal', 'Continue'),
        logout: this.$pgettext('open_current_account_modal', 'Log out'),
        askLater: this.$pgettext('open_current_account_modal', 'Ask later'),
        continueText: this.$pgettext('open_current_account_modal',
          'For more detailed information, please visit ' +
          "Bigbank's <a target='_blank' href='#'>website</a>, " +
          "<a target='_blank' href='#'>General terms</a> and " +
          "<a target='_blank' href='#'>Payment Transaction Terms</a>.")
      }

      return translations
    },
    modalProps () {
      return {
        flavor: 'card',
        fullMobile: true,
        centerVertically: true,
        hideClose: !this.isMobile,
        staticOverlay: true,
        visible: true,
        publicSize: 'm'
      }
    },
    cancelButtonProps () {
      const defaults = {
        color: 'gray',
        inverted: false,
        label: this.translations[!this.canSkipCurrentAccountFlow ? 'logout' : 'askLater'],
        disabled: this.areActionButtonsDisabled,
        noBackground: true,
        isLoading: this.isLoading,
        size: 'md',
        noPaddings: true
      }

      if (this.isMobile) {
        return {
          ...defaults,
          noBackground: false,
          inverted: true,
          display: 'block'
        }
      }

      return {
        ...defaults,
        class: 'w-100'
      }
    },
    continueButtonProps () {
      const defaults = {
        class: 'w-100',
        label: this.translations.continue,
        disabled: this.areActionButtonsDisabled,
        size: 'md'
      }

      return {
        ...defaults
      }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['checkAuthentication']),
    initiateLogout () {
      this.areActionButtonsDisabled = true
      this.$router.push({ path: '/logout', query: { reason: LogoutReason.OPEN_CURRENT_ACCOUNT_MODAL } })
    },
    onContinue () {
      document.location = `/gw/current-account-onboarding?lang=${this.language}`
    },
    async onCancel () {
      if (!this.canSkipCurrentAccountFlow) {
        this.initiateLogout()
      } else {
        this.isLoading = true
        this.areActionButtonsDisabled = true
        try {
          await api.skipCurrentAccountConversion()
          this.$router.push({ path: '/' })
        } finally {
          this.isLoading = false
          this.areActionButtonsDisabled = false
        }
      }
    }
  },
  async mounted () {
    await this.checkAuthentication()
  }
}
</script>

<style lang="scss" scoped>
.header {
  color: $blue;
  font-weight: 500;
  font-size: $font-size-small;
  font-family: $gotham-medium;
  line-height: 18px;
  text-align: center;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    max-width: 250px;

    @media (max-width: $mobile-view-breaking-point) {
      max-width: 155px;
    }
  }

  .current-account-modal__list-item {
    display: flex;
  }

  .text {
    color: $gray;
    font-family: $gotham-book;
    line-height: 18px;

  }

  ::v-deep a {
    color: $blue;
  }
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  padding: 0 10px 10px;
  align-items: stretch;

  @media (max-width: $mobile-view-breaking-point) {
    padding: 20px;
  }

  div {
    flex: 1 0 0;
    display: flex;
  }
}
</style>
