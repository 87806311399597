import { defineStore } from 'pinia'
import api from '@loan/api'
import { EMPTY_FILTERS_FORM_DATA } from '@credit-card/components/credit-card.const'
import { TransactionSettingsItem } from '@bigbank/dc-common/enums/loan.enums'
import { TransactionAdvancedSearchOption, TransactionsPeriodFilter } from '@bigbank/dc-common/enums/credit-card.enums'
import { transformFormDataIntoSearchRequestBody } from '@credit-card/components/util/transactions.util'
import { getEmptyAccountStatementData } from '@/components/transactions/transactions.helpers'
import { CREDIT_CARD } from '@/const'
import dayjs from 'dayjs'
import { useRootStore } from '@/store/root'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'
import { sortByActiveStatus } from '@loan/helpers/sort-contracts'
import { useLoanStore } from '@loan/store/loanStore'

export const useCreditCardStore = defineStore('credit-card', {
  state: () => {
    return {
      creditCards: [],
      areCreditCardButtonsEnabled: true,
      creditCardAccountStatement: getEmptyAccountStatementData('credit-card'),
      creditCardTransactionsSearchRequest: null,
      creditCardTransactionsSettings: {
        [TransactionSettingsItem.FilteringPeriods]: {},
        [TransactionSettingsItem.FilteringAdvancedSearchOptions]: TransactionAdvancedSearchOption,
        [TransactionSettingsItem.FilteringPeriodByDefault]: TransactionsPeriodFilter.CurrentYear,
        [TransactionSettingsItem.FilteringAdvancedSearchOptionByDefault]: TransactionAdvancedSearchOption.AnyFieldContains,
        [TransactionSettingsItem.FilteringDatePickerLimits]: {
          min: '2022-06-01',
          max: dayjs().format('YYYY-MM-DD')
        }
      },
      creditCardHeroHeaderData: {
        availableLimitAmount: null,
        reservations: null
      },
      creditCardPricing: {
        monthlyFee: null,
        baseInterest: null,
        maxInterestFreePeriod: null,
        cardReplacement: null
      }
    }
  },
  getters: {
    hasCreditCardContract () {
      return useRootStore().contractsCount?.totalByType?.contract?.all?.[InternalLoanType.CREDIT_CARD] > 0
    },
    creditCardContract () {
      return useLoanStore().contracts
        .filter(({ typeCode }) => typeCode === InternalLoanType.CREDIT_CARD)
        .sort(sortByActiveStatus)[0] ?? null
    }
  },
  actions: {
    init () {
      this.contractAdditionalDetails = null
    },
    async getAvailableMonthlyLimit () {
      return await api.getAvailableMonthlyLimit()
    },
    async getContractAdditionalDetails (contractId) {
      const details = await api.getLoanContractById(contractId)
      this.contractAdditionalDetails = details
      this.creditCardHeroHeaderData = {
        availableLimitAmount: details?.balanceDetails?.availableLimitAmount,
        reservations: details?.balanceDetails?.reservations
      }

      return details
    },
    async initCreditCardTransactions (contractId) {
      const settings = await this.fetchCreditCardTransactionsSettings()
      const defaultPeriod = settings[TransactionSettingsItem.FilteringPeriodByDefault]
      const filters = {
        ...EMPTY_FILTERS_FORM_DATA,
        ...settings[TransactionSettingsItem.FilteringPeriods][defaultPeriod],
        period: defaultPeriod
      }

      this.creditCardTransactionsSearchRequest = transformFormDataIntoSearchRequestBody(filters, CREDIT_CARD.TRANSACTIONS_PER_PAGE, 1)
      await this.fetchCreditCardAccountStatement({ contractId })
    },
    async getCreditCards (contractId) {
      const loanStore = useLoanStore()
      loanStore.setLoading(true)
      this.creditCards = await api.getCreditCardsByContract(contractId)
      loanStore.setLoading(false)
    },
    resetCreditCards () {
      this.creditCards = []
      this.creditCardHeroHeaderData = {
        availableLimitAmount: null,
        reservations: null
      }
    },
    async refreshCreditCard (cardId) {
      const updatedCard = await api.getCreditCardByCardId(cardId)
      const creditCards = this.creditCards.map(card => {
        if (card.id.toString() === updatedCard.id.toString()) {
          return updatedCard
        }
        return card
      })

      this.creditCards = creditCards
    },
    async blockCreditCard ({ contractId, cardId, reason, orderNewCard }) {
      this.areCreditCardButtonsEnabled = false

      await api.blockCreditCard(cardId, {
        reason,
        orderNewCard
      })

      if (orderNewCard) {
        await this.getCreditCards(contractId)
      } else {
        await this.refreshCreditCard(cardId)
      }

      this.areCreditCardButtonsEnabled = true
    },
    async orderCreditCard (contractId, cardType) {
      const { id } = await api.orderCreditCard(contractId, cardType)

      return id
    },
    updateCreditCardTransactionsSearchRequest (filters) {
      this.creditCardTransactionsSearchRequest = filters
    },
    async fetchCreditCardAccountStatement ({ contractId }, append = false) {
      if (!useLoanStore().featureFlags?.enableCreditCardTransactionsOverview) {
        return
      }
      const results = await api.getTransactionsUsingContractId(contractId, this.creditCardTransactionsSearchRequest)
      if (append) {
        this.creditCardAccountStatement.transactions.push(...results.transactions)
      } else {
        this.creditCardAccountStatement = results
      }
    },
    async fetchCreditCardTransactionsSettings () {
      const settings = await api.getTransactionsSettings([
        TransactionSettingsItem.FilteringPeriods,
        TransactionSettingsItem.FilteringAdvancedSearchOptions,
        TransactionSettingsItem.FilteringPeriodByDefault,
        TransactionSettingsItem.FilteringAdvancedSearchOptionByDefault,
        TransactionSettingsItem.FilteringDatePickerLimits
      ])

      this.creditCardTransactionsSettings = settings
      return settings
    },
    async fetchCreditCardPricing () {
      this.creditCardPricing = await api.getCreditCardPricing()
    }
  }
})
