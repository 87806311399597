<template>
  <div v-if="processStarted">
    <component
      v-if="signingComponent"
      :is="signingComponent"
      v-bind="signingMethodProps"
      @signingSuccessful="onSigned"
      @signingError="onSigningError"
      @close="onClose"
    />
    <signing-error-messages v-else-if="method" v-bind="errorProps" @close="onClose" />
    <signing-modal-wrapper v-else visible showLoader @close="onClose" />
  </div>
</template>

<script>
import { AuthMethod } from '@bigbank/dc-common/config'
import SigningModalWrapper from '@/components/signing/SigningModalWrapper'
import SigningErrorMessages from '@/components/signing/SigningErrorMessages'
import SignWithSmartId from './SignWithSmartId'
import SignWithMobileId from './SignWithMobileId'
import SignWithIdCard from './SignWithIdCard'
import SignWithEparaksts from './SignWithEparaksts'
import { isNil } from 'lodash'

export default {
  name: 'signing-modal',
  components: {
    SigningModalWrapper,
    SigningErrorMessages,
    SignWithSmartId,
    SignWithMobileId,
    SignWithIdCard,
    SignWithEparaksts
  },
  props: {
    initMethod: {
      required: true
    }
  },
  data () {
    return {
      processStarted: false,
      method: null,
      signingRequestId: null,
      translations: {
        useAnotherMethod: this.$pgettext('signing_modal', 'Please use another method')
      }
    }
  },
  computed: {
    signingComponent () {
      return this.method && {
        [AuthMethod.SMART_ID]: 'sign-with-smart-id',
        [AuthMethod.MOBILE_ID]: 'sign-with-mobile-id',
        [AuthMethod.ID_CARD]: 'sign-with-id-card',
        [AuthMethod.EPARAKSTS]: 'sign-with-eparaksts'
      }[this.method]
    },
    signingRequestIdFromQuery () {
      return this.$route.query.signingRequestId
    },
    signingMethodProps () {
      return {
        signingRequestId: this.signingRequestId
      }
    },
    errorProps () {
      return {
        code: 'UNKNOWN_SIGN_METHOD',
        button: 'logout',
        titleOverride: this.translations.useAnotherMethod,
        showAlertIcon: true,
        visible: true
      }
    }
  },
  async mounted () {
    if (this.hasReturnedAfterSigning()) {
      this.signingRequestId = this.signingRequestIdFromQuery
      this.method = this.$route.query.signingMethod
      this.processStarted = true
    }
  },
  methods: {
    hasReturnedAfterSigning () {
      return !isNil(this.$route.query.callback)
    },
    cleanUpEparaksts () {
      if (this.hasReturnedAfterSigning()) {
        const paymentId = this.$route.query.paymentId

        this.$router.replace({
          query: {
            ...this.$route.query,
            signingRequestId: undefined,
            signingMethod: undefined,
            paymentId: undefined,
            callback: undefined
          }
        })
        return paymentId
      }
    },
    async signButtonClick (e) {
      e.preventDefault()
      this.reset()
      this.processStarted = true
      const { method, signingRequestId } = await this.initMethod()
      this.method = method
      this.signingRequestId = signingRequestId

      return false
    },
    onSigned (resp) {
      this.reset()
      const paymentId = this.cleanUpEparaksts()
      this.$emit('signingSuccessful', { ...resp, paymentId })
    },
    onClose () {
      this.reset()
      const paymentId = this.cleanUpEparaksts()
      this.$emit('signingCancelled', { paymentId })
    },
    onSigningError (error) {
      // reset only if INTERNAL_ERROR, as in other cases user must be able to click 'Try again' in signing modal
      if (error.errorCode === 'INTERNAL_ERROR') {
        this.reset()
        this.$emit('signingError', error)
      }
    },
    reset () {
      this.processStarted = false
      this.initResult = null
      this.method = null
      this.signingRequestId = null
    }
  }
}
</script>
