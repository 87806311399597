<template>
  <div>
    <signing-modal
      ref="signing"
      :initMethod="initEditLimitsSigning"
      @signingSuccessful="onSuccessfulEditLimitsSigning"
      @signingError="onSigningError"
    />
    <bb-modal :visible="visible" flavor="card" public-size="m" @close="closeSummaryModal" fullMobile>
      <template #heading>
        <p class="f-color-blue">{{ translations.limitsSummary }}</p>
      </template>
      <bb-form data-testid="edit-limits-summary">
        <p class="f-small f-gotham-book text-center m-b-10">{{ translations.limitsChangedForText }}</p>
        <p class="f-small text-center f-color-gray m-b-25">{{ accountNumber }}</p>
        <bb-box thin class="limit-box bg-gray-10 m-b-10">
          <bb-row>
            <div class="col bb-col f-smallest align-content-center">{{ translations.newDailyLimit }}</div>
            <div class="col bb-col f-align-right f-color-mint f-small" data-testid="edit-limits-summary-daily-limit">
              {{ formatLimit(requestedDailyLimit) }}
            </div>
          </bb-row>
        </bb-box>
        <bb-box thin class="limit-box bg-gray-10 m-b-15">
          <bb-row>
            <div class="col bb-col f-smallest align-content-center">{{ translations.newMonthlyLimit }}</div>
            <div class="col bb-col f-align-right f-color-mint f-small" data-testid="edit-limits-summary-monthly-limit">
              {{ formatLimit(requestedMonthlyLimit) }}
            </div>
          </bb-row>
        </bb-box>
        <p class="f-smallest f-gotham-book">{{ accountLimitsChangeScenarioExplanationMessage }}</p>
        <div slot="submit" slot-scope="{}"/>
      </bb-form>
      <template #footer>
        <div class="d-flex justify-content-between w-100 summary-modal-buttons">
          <bb-button
            :label="translations.goBackToEdit"
            color="gray"
            inverted
            display="block"
            class="m-r-15 flex-basis-50 flex-md-basis-auto f-tiny-xs f-smallest-md"
            data-testid="edit-limits-summary-go-back-to-edit"
            @click="goBackToEdit"
          />
          <bb-button
            :label="translations.signWithPin2"
            type="submit"
            display="block"
            class="flex-basis-50 flex-md-basis-auto f-tiny-xs f-smallest-md"
            data-testid="edit-limits-summary-sign-button"
            @click="onSign"
          />
        </div>
      </template>
    </bb-modal>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useRootStore } from '@/store/root'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import SigningModal from '@account/components/signing/SigningModal.vue'
import api from '@account/api'
import { AuthMethod } from '@bigbank/dc-common/config'
import { AccountLimitsChangeStatus } from '@account/const'

export default {
  name: 'edit-limit-summary-modal',
  components: { SigningModal },
  props: {
    visible: {
      type: Boolean
    },
    accountId: {
      type: Number,
      required: true
    },
    accountNumber: {
      type: String,
      required: true
    },
    requestedDailyLimit: {
      type: Number,
      required: true
    },
    requestedMonthlyLimit: {
      type: Number,
      required: true
    },
    previousDailyLimit: {
      type: Number,
      required: true
    },
    previousMonthlyLimit: {
      type: Number,
      required: true
    },
    maximumAccountLimit: {
      type: Number,
      required: true
    },
    moneyPurposeAnswer: {
      type: String,
      default: ''
    },
    moneyOriginAnswer: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(useRootStore, ['currency', 'locale']),
    translations () {
      return {
        limitsSummary: this.$pgettext('EDB_limits', 'Summary'),
        limitsChangedForText: this.$pgettext('EDB_limits', 'You have changed limits for account'),
        newDailyLimit: this.$pgettext('EDB_limits', 'New daily limit'),
        newMonthlyLimit: this.$pgettext('EDB_limits', 'New monthly limit'),
        goBackToEdit: this.$pgettext('EDB_limits', 'Go back to edit'),
        signWithPin2: this.$pgettext('EDB_limits', 'Sign with PIN2'),
        accountLimitSuccessfulChangeTitle: this.$pgettext('EDB_limits', 'Account limit changed successfully'),
        accountLimitSuccessfulChange: this.$pgettext('EDB_limits', 'Account limits changed successfully. New account limits are effective immediately.'),
        accountLimitChangeToBeReviewedTitle: this.$pgettext('EDB_limits', 'Account limit need a review'),
        accountLimitChangeToBeReviewed: this.$pgettext('EDB_limits', 'Limit change needs to be reviewed. Before applying new limits, we need to review the limit change request.'),
        accountLimitUnsuccessfulChangeTitle: this.$pgettext('EDB_limits', 'Account limit changed unsuccessfully'),
        accountLimitUnsuccessfulChange: this.$pgettext('EDB_limits', 'New limits were not applied. Server error, could not save changes, please try again later.'),
        toBeReviewedLimitsLimitsChangeNotification: this.$pgettext('EDB_limits', 'Please note that once you sign the request, we will need to review the limit request before we can enforce the new limits. It may take up to 3 working days.'),
        immediateLimitsChangeNotification: this.$pgettext('EDB_limits', 'The requested limits will take effect immediately, once you have signed the request.')
      }
    },
    isOverMaximumAccountLimit () {
      return this.requestedMonthlyLimit > this.maximumAccountLimit ||
        this.requestedDailyLimit > this.maximumAccountLimit
    },
    accountLimitsChangeScenarioExplanationMessage () {
      return this.isOverMaximumAccountLimit
        ? this.translations.toBeReviewedLimitsLimitsChangeNotification
        : this.translations.immediateLimitsChangeNotification
    }
  },
  methods: {
    closeSummaryModal () {
      this.$emit('close')
    },
    goBackToEdit () {
      this.$emit('goBackToEdit')
    },
    formatLimit (number) {
      return formatMoneyWithCurrency(number, this.currency, 'et', { fractionDigits: 0 })
    },
    onSign () {
      this.$refs.signing.signButtonClick(new MouseEvent('click', {}))
    },
    async initEditLimitsSigning () {
      try {
        this.closeSummaryModal()
        return await api.initLimitsChange({
          accountId: this.accountId,
          previousDailyLimit: this.previousDailyLimit,
          previousMonthlyLimit: this.previousMonthlyLimit,
          requestedDailyLimit: this.requestedDailyLimit,
          requestedMonthlyLimit: this.requestedMonthlyLimit,
          purposeOfLimitChangeQ1: this.moneyPurposeAnswer,
          purposeOfLimitChangeQ2: this.moneyOriginAnswer
        })
      } catch (e) {
        this.showLimitsChangeErrorNotification()
        return { method: e.data.signingMethod }
      }
    },
    onSuccessfulEditLimitsSigning (signingResponse) {
      if (this.isUnknownAccountLimitsChangeStatus(signingResponse.limitsChangeStatus)) {
        this.showLimitsChangeErrorNotification()
      } else {
        const translations = this.getTranslationsForSuccessfulSigning(signingResponse.limitsChangeStatus)
        this.$notify({
          title: translations.title,
          text: translations.body,
          type: translations.type,
          duration: 10000
        })
        this.$emit('accountLimitsChangeSigned')
      }
    },
    onSigningError () {
      this.showLimitsChangeErrorNotification()
    },
    showLimitsChangeErrorNotification () {
      this.$notify({
        title: this.translations.accountLimitUnsuccessfulChangeTitle,
        text: this.translations.accountLimitUnsuccessfulChange,
        type: 'error',
        duration: 10000
      })
    },
    isUnknownAccountLimitsChangeStatus (status) {
      return ![AccountLimitsChangeStatus.AutoApproved, AccountLimitsChangeStatus.Pending].includes(status)
    },
    getTranslationsForSuccessfulSigning (limitsChangeStatus) {
      if (limitsChangeStatus === AccountLimitsChangeStatus.AutoApproved) {
        return {
          title: this.translations.accountLimitSuccessfulChangeTitle,
          body: this.translations.accountLimitSuccessfulChange,
          type: 'success'
        }
      } else if (limitsChangeStatus === AccountLimitsChangeStatus.Pending) {
        return {
          title: this.translations.accountLimitChangeToBeReviewedTitle,
          body: this.translations.accountLimitChangeToBeReviewed,
          type: 'info'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .summary-modal-buttons {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-bottom: 30px !important;
  }
}
</style>
