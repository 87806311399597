<template>
  <signing-modal-wrapper
      :visible="visible"
      @close="$emit('close', $event)"
      :showLoader="isLoading">
    <template slot="heading"><translate>{{translations.title}}</translate></template>
    <p v-if="showErrorMessage" class="error">
      <signing-error-messages
          :code="errorCode"
          :type="signingMethod"
          :visible="showErrorMessage"
          :data="errorData"
          @close="closeModal"
      />
    </p>
    <signature-verification-modal
        :visible="showModal"
        @close="closeModal"
        :challengeId="challengeId"
        :errorCode="errorCode"
        :type="signingMethod"/>
  </signing-modal-wrapper>
</template>

<script>
import { SIGNING_METHODS } from '@bigbank/dc-common/enums/loginAndSigningMethods'
import SigningErrorMessages from './SigningErrorMessages'
import SignatureVerificationModal from './SignatureVerificationModal'
import SigningModalWrapper from './SigningModalWrapper'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { ErrorTextCode: BackendErrorTextCode } = require('../../../server/error-text-code.enum')

export default {
  name: 'mobile-id-signing',
  components: { SignatureVerificationModal, SigningErrorMessages, SigningModalWrapper },
  data () {
    return {
      showModal: false,
      challengeId: '',
      isLoading: false,
      errorCode: null,
      errorData: null,
      signingMethod: SIGNING_METHODS.MOBILE_ID
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    startSigning: {
      required: true
    },
    completeSigning: {
      required: true
    }
  },
  computed: {
    translations () {
      return {
        title: this.$gettextInterpolate(
          this.$pgettext('signing_form_header_smartid', 'Signature with Mobile-ID')
        ),
        loading: this.$pgettext('loading', 'Loading please wait...')
      }
    },
    showErrorMessage () {
      return !!this.errorCode
    }
  },
  methods: {
    async startSigningProcess () {
      try {
        this.isLoading = true
        this.errorCode = null

        const response = await this.startSigning()

        if (!this.responseErrorHandler(response)) { return }

        this.challengeId = response.challengeId

        this.showModal = true
        this.isLoading = false

        await this.pollStatus()
      } catch (error) {
        this.isLoading = false
        if (error.errCode || error.err_code) {
          this.errorCode = error.errCode ? error.errCode : error.err_code
        } else { this.errorCode = error.type }
        this.$emit('signingError', error)
      }
    },
    async pollStatus () {
      if (!this.showModal) {
        return
      }
      try {
        const response = await this.completeSigning()
        if (!this.responseErrorHandler(response)) { return }
        if (response.status === 'COMPLETED_OK') {
          this.showModal = false
          this.$emit('signingSuccessful', response)
        } else if (response.status === 'RUNNING') {
          setTimeout(() => this.pollStatus(), 3000)
        }
      } catch (error) {
        this.isLoading = false
        if (error.errCode || error.err_code) {
          this.errorCode = error.errCode ? error.errCode : error.err_code
        } else { this.errorCode = error.type }
        this.$emit('signingError', error)
      }
    },
    responseErrorHandler (response) {
      if (response && !(response.errCode || response.err_code)) {
        return true // no error, valid response
      }

      if (response?.err_code === BackendErrorTextCode.NOT_CLIENT) {
        // Error will be handled within errorHandler
        this.closeModal()
      } else if (!response) {
        this.errorCode = 'DEFAULT'
      } else {
        this.errorCode = response.errCode ? response.errCode : response.err_code
      }

      return false // on error
    },
    closeModal () {
      this.showModal = false
      this.errorCode = null
      this.$emit('close')
    }
  },
  mounted () {
    this.startSigningProcess()
  }
}
</script>

<style lang="scss" scoped>

.error {
  color: $red;
  font-family: $gotham-medium;
  margin-top: 20px;
  text-align: center;
  font-size: $font-size-smallest;
}

.loading {
  font-family: $gotham-medium;
  margin-top: 20px;
  text-align: center;
  font-size: $font-size-small;
}
</style>
