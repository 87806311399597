const { useRootStore } = require('@/store/root')
const { LandingPageRouteNames } = require('@/views/const')
const { AccountRouteName } = require('@/modules/account/const')
const { ROUTER_PATHS } = require('@/const')

export default function (to, from, next) {
  const rootStore = useRootStore()

  if (LandingPageRouteNames.includes(from.name) || from.name === AccountRouteName.Dashboard) {
    rootStore.setBackButtonRouteNameOverride(from.name)
  }

  rootStore.setPreviousRoute(from)
  const permissions = rootStore.permissions
  const allowedPaths = [ROUTER_PATHS.CONFIRM_DATA]

  if (
    to.meta.guarded !== false &&
    !allowedPaths.includes(to.path) &&
    permissions &&
    !permissions.view.allowed
  ) {
    if (rootStore.isDocumentUploadSkippedInConfirmData) {
      return next()
    } else if (permissions.view.confirmData) {
      rootStore.setPreviousRoute(to)
      return next(ROUTER_PATHS.CONFIRM_DATA)
    } else {
      return next('/oops/no-access')
    }
  }

  return next()
}
