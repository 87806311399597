export const PaymentStatus = Object.freeze({
  Success: 'success',
  Failure: 'failure'
})

export const PAYMENT_DESCRIPTION_MAX_LENGTH = 115
export const PAYMENT_REFERENCE_MAX_LENGTH = 20
export const PAYMENT_BENEFICIARY_MAX_LENGTH = 70
export const PREDEFINED_PAYMENT_NAME_MAX_LENGTH = 70
export const PREDEFINED_PAYMENT_MAX_COUNT = 500
