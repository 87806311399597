const { useRootStore } = require('@/store/root')
const LogoutReason = require('@/types/logout-reason')

export default async function (to, from, next) {
  const rootStore = useRootStore()

  if (!rootStore.isEnvironmentLoaded) {
    await rootStore.loadEnvironment()
  }

  if (to.meta.guarded !== false && !rootStore.areTermsAccepted) {
    const isLoggedIn = await rootStore.checkAuthentication(false)

    if (isLoggedIn) {
      if (rootStore.permissions?.options?.isAccessDenied ?? false) return next('/oops/no-access')
      if (!rootStore.areTermsAccepted) {
        return next('/terms?redirect=' + to.fullPath)
      }
    } else {
      return next(`/logout?reason=${LogoutReason.TERMS_ACCEPTED_BUT_CHECK_AUTHENTICATION_FAILED}`)
    }
  } else if (!to.meta.guarded) {
    rootStore.setInitialAuthCheckMade()
  }
  return next()
}
