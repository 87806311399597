<template>
  <bb-modal :visible="visible" flavor="card" public-size="m" @close="closeModal" fullMobile>
    <template #heading>
      <p class="f-color-blue">{{ translations.editLimits }}</p>
    </template>
    <bb-form
      @progress="progressEvent"
      data-testid="edit-limits-form"
    >
      <p class="f-small text-center f-color-gray m-b-15">{{ accountNumber }}</p>
      <bb-box thin class="limit-box bg-gray-10 m-b-10">
        <div>
          <bb-input
            v-model="dailyLimit"
            :label="translations.dailyLimit"
            name="daily-limit"
            framed
            currency
            :add-on="currencySymbol"
            class="m-b-10"
            :rules="dailyValidationRules"
            :messages="dailyValidationMessages"
            data-vv-validate-on="change"
          />
          <limit-box-progress
            v-bind="dailyLimitProgressProps"
          />
        </div>
      </bb-box>
      <bb-box thin class="limit-box bg-gray-10">
        <div>
          <bb-input
            v-model="monthlyLimit"
            :label="translations.monthlyLimit"
            name="monthly-limit"
            framed
            currency
            :add-on="currencySymbol"
            class="m-b-10"
            :rules="monthlyValidationRules"
            :messages="monthlyValidationMessages"
            data-vv-validate-on="change"
          />
          <limit-box-progress
            v-bind="monthlyLimitProgressProps"
          />
        </div>
      </bb-box>
      <bb-box
        v-show="isCustomerNotF2fIdentifiedAndAdditionalLimitsEnabled"
        thin
        class="bg-gray-10 color-gray f-smallest m-y-15 not-identified"
      >
        <div class="d-flex m-b-5">
          <bb-icon name="ui-alert-outline" fill="yellow" size="24" />
          <p class="m-l-5 not-identified__title">{{ translations.notIdentified }}</p>
        </div>
        <span class="f-gotham-book"> {{ translations.notIdentifiedText }}</span>
        <bb-link :to="{ name: AccountRouteName.AccountVerification }" class="color-mint">
          {{ translations.notIdentifiedClickHere }}
        </bb-link>
      </bb-box>
      <bb-box
        v-if="shouldAskCustomerForMoneyOriginAndPurpose"
        thin
        class="bg-gray-10 f-smallest m-y-10 over-maximum-limit"
      >
        <div class="d-flex m-b-5">
          <bb-icon name="ui-alert-outline" fill="yellow" size="24" />
          <p class="m-l-5 over-maximum-limit__title">{{ translations.overMaximumLimit }}</p>
        </div>
        <span class="f-gotham-book">{{ translations.overMaximumLimitText }}</span>
        <bb-input
          v-model="moneyPurposeAnswer"
          :label="translations.moneyPurposeQuestionText"
          name="money-purpose-answer"
          framed
          mandatory
          multilineLabel
          :rules="moneyPurposeAnswerValidationRules"
          :messages="moneyPurposeAnswerValidationMessages"
          :placeholder="translations.moneyPurposeAnswerPlaceholder"
          data-vv-validate-on="change"
        />
        <bb-input
          v-model="moneyOriginAnswer"
          :label="translations.moneyOriginQuestionText"
          name="money-origin-answer"
          framed
          mandatory
          multilineLabel
          :rules="moneyOriginAnswerValidationRules"
          :messages="moneyOriginAnswerValidationMessages"
          :placeholder="translations.moneyOriginAnswerPlaceholder"
          data-vv-validate-on="change"
        />
      </bb-box>
      <div slot="submit"/>
    </bb-form>
    <template #footer>
      <bb-button
        @click="goToSummary"
        :label="translations.saveChanges"
        :disabled="isSubmitDisabled"
        :size="submitButtonSize"
        display="block"
        corner="sharpMobileOnly"
        data-testid="edit-limits-submit-button"
      />
    </template>
  </bb-modal>
</template>

<script>
import { mapState } from 'pinia'
import { useRootStore } from '@/store/root'
import LimitBoxProgress from './LimitBoxProgress.vue'
import { currencyCodeToSymbol } from '@/plugins/currencyFormatters'
import { useAccountStore } from '@account/store/accountStore'
import { AccountMoneyOriginAnswerMaxLength, AccountMoneyPurposeAnswerMaxLength, AccountRouteName } from '@account/const'

export default {
  name: 'edit-limit-modal',
  data () {
    return {
      loading: false,
      dailyLimit: 0,
      monthlyLimit: 0,
      moneyPurposeAnswer: '',
      moneyOriginAnswer: '',
      customerMaxAccountLimit: 0,
      AccountRouteName,
      isFormValid: true
    }
  },
  components: {
    LimitBoxProgress
  },
  props: {
    visible: {
      type: Boolean
    },
    accountNumber: {
      type: String,
      required: true
    },
    dailyLimitProgressProps: {
      type: Object,
      validator: (val) => {
        return true
      }
    },
    monthlyLimitProgressProps: {
      type: Object,
      validator: (val) => {
        return true
      }
    },
    draftDailyLimit: {
      type: Number,
      required: true
    },
    draftMonthlyLimit: {
      type: Number,
      required: true
    },
    maximumAccountLimit: {
      type: Number,
      required: true
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.dailyLimit = this.draftDailyLimit
        this.monthlyLimit = this.draftMonthlyLimit
      }
    },
    monthlyLimit (val) {
      this.monthlyLimit = val
    }
  },
  computed: {
    ...mapState(useRootStore, ['currency', 'locale', 'featureFlags']),
    ...mapState(useAccountStore, ['verificationData']),
    currencySymbol () {
      return currencyCodeToSymbol(this.currency)
    },
    isLimitOver15kAndCustomerUnidentifiedWithAdditionalLimitsEnabled () {
      if (this.verificationData.isF2fIdentified || !this.featureFlags.enableUnverifiedCustomerAdditionalLimits) {
        return false
      }
      return this.dailyLimit > 15000 || this.monthlyLimit > 15000
    },
    isLimitOverMaximum () {
      return this.dailyLimit > this.maximumAccountLimit || this.monthlyLimit > this.maximumAccountLimit
    },
    shouldAskCustomerForMoneyOriginAndPurpose () {
      return this.shouldCheckLimitsForCustomer && this.isLimitOverMaximum
    },
    shouldCheckLimitsForCustomer () {
      return this.verificationData.isF2fIdentified || !this.featureFlags.enableUnverifiedCustomerAdditionalLimits
    },
    dailyValidationRules () {
      return {
        required: Boolean,
        max_value: v => v <= this.monthlyLimit,
        max_length: v => String(v).length <= 7,
        no_symbols: v => !/[^0-9]/.test(v)
      }
    },
    dailyValidationMessages () {
      return {
        error: {
          required: this.translations.errors.dailyLimitRequired,
          max_value: this.translations.errors.dailyLimitShouldNotExceedMonthly,
          max_length: this.translations.errors.dailyLimitTooLong,
          no_symbols: this.translations.errors.dailyLimitNoSymbols
        }
      }
    },
    monthlyValidationRules () {
      return {
        required: Boolean,
        min_value: v => v >= this.dailyLimit,
        max_length: v => String(v).length <= 7,
        no_symbols: v => !/[^0-9]/.test(v)
      }
    },
    monthlyValidationMessages () {
      return {
        error: {
          required: this.translations.errors.monthlyLimitRequired,
          min_value: this.translations.errors.dailyLimitShouldNotExceedMonthly,
          max_length: this.translations.errors.monthlyLimitTooLong,
          no_symbols: this.translations.errors.monthlyLimitNoSymbols
        }
      }
    },
    moneyPurposeAnswerValidationRules () {
      return {
        required: Boolean,
        max_length: v => String(v).length <= AccountMoneyPurposeAnswerMaxLength
      }
    },
    moneyPurposeAnswerValidationMessages () {
      return {
        error: {
          required: this.translations.errors.moneyPurposeAnswerRequired,
          max_length: this.translations.errors.moneyPurposeAnswerTooLong
        }
      }
    },
    moneyOriginAnswerValidationRules () {
      return {
        required: Boolean,
        max_length: v => String(v).length <= AccountMoneyOriginAnswerMaxLength
      }
    },
    moneyOriginAnswerValidationMessages () {
      return {
        error: {
          required: this.translations.errors.moneyOriginAnswerRequired,
          max_length: this.translations.errors.moneyOriginAnswerTooLong
        }
      }
    },
    translations () {
      return {
        dailyLimit: this.$pgettext('EDB_limits', 'Daily limit'),
        monthlyLimit: this.$pgettext('EDB_limits', 'Monthly limit'),
        editLimits: this.$pgettext('EDB_limits', 'Edit limits'),
        saveChanges: this.$pgettext('EDB_limits', 'Save changes'),
        notIdentified: this.$pgettext('EDB_limits', 'Customer is not identified'),
        notIdentifiedText: this.$pgettext('EDB_limits', 'Everyone who has not done Face to Face identification, has a monthly spending limit of €15,000. This includes internet bank transfers. To learn more'),
        notIdentifiedClickHere: this.$pgettext('EDB_limits', 'click here'),
        overMaximumLimit: this.$pgettext('EDB_limits', 'Additional information needed'),
        overMaximumLimitText: this.$pgettext('EDB_limits', 'Please provide us with the necessary additional information in order to review your request to increase the transfer limits as soon as possible.'),
        moneyPurposeQuestionText: this.$pgettext('EDB_limits', 'What transactions are you planning to make within the new limits?'),
        moneyPurposeAnswerPlaceholder: this.$pgettext('EDB_limits', 'Placeholder'),
        moneyOriginQuestionText: this.$pgettext('EDB_limits', 'Where does the money you plan on using for these transactions originate from?'),
        moneyOriginAnswerPlaceholder: this.$pgettext('EDB_limits', 'Placeholder'),
        errors: {
          dailyLimitRequired: this.$pgettext('EDB_limits', 'Daily limit field is required'),
          dailyLimitShouldNotExceedMonthly: this.$pgettext('EDB_limits', 'Daily limit should not exceed monthly limit'),
          monthlyLimitRequired: this.$pgettext('EDB_limits', 'Monthly limit field is required'),
          dailyLimitTooLong: this.$pgettext('EDB_limits', 'Daily limit is too long'),
          monthlyLimitTooLong: this.$pgettext('EDB_limits', 'Monthly limit is too long'),
          dailyLimitNoSymbols: this.$pgettext('EDB_limits', 'Daily limit should be round number'),
          monthlyLimitNoSymbols: this.$pgettext('EDB_limits', 'Monthly limit should be round number'),
          moneyPurposeAnswerRequired: this.$pgettext('EDB_limits', 'New limits purpose is required'),
          moneyPurposeAnswerTooLong: this.$pgettext('EDB_limits', 'Answer should not be longer than 250 characters'),
          moneyOriginAnswerRequired: this.$pgettext('EDB_limits', 'Money origin is required'),
          moneyOriginAnswerTooLong: this.$pgettext('EDB_limits', 'Answer should not be longer than 250 characters')
        }
      }
    },
    isSubmitDisabled () {
      return this.isLimitOver15kAndCustomerUnidentifiedWithAdditionalLimitsEnabled || !this.isFormValid
    },
    submitButtonSize () {
      return this.$screen.md ? 'md' : 'lg'
    },
    isCustomerNotF2fIdentifiedAndAdditionalLimitsEnabled () {
      return !this.verificationData.isF2fIdentified && this.featureFlags.enableUnverifiedCustomerAdditionalLimits
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },
    goToSummary () {
      this.$emit('goToEditSummary', {
        dailyLimit: this.dailyLimit,
        monthlyLimit: this.monthlyLimit,
        moneyPurposeAnswer: this.moneyPurposeAnswer,
        moneyOriginAnswer: this.moneyOriginAnswer
      })
    },
    progressEvent (value) {
      this.isFormValid = value === 1
    }
  }
}
</script>
<style lang="scss" scoped>
.not-identified__title {
  line-height: 24px;
}

.over-maximum-limit__title {
  line-height: 24px;
}
</style>
