<template>
  <layout :loading="loading">
    <bb-container fluid v-if="!loading" class="accounts-limits">
      <bb-row no-gutters class="m-b-50">
        <bb-col>
          <h4 class="accounts-limits__title color-navy f-gotham-book m-y-20 m-t-md-0">
            {{ translations.title }}
          </h4>
          <bb-box thin class="w-full bg-white p-x-md-25 m-b-30 f-medium">
            <p class="f-small color-navy f-gotham-book" data-testid="accounts-limits-personal-information"><span class="f-medium">{{ username }}</span> &#x2022; {{ identificationCode }}</p>

            <bb-box
              v-show="isCustomerNotF2fIdentifiedAndAdditionalLimitsEnabled"
              thin
              class="color-gray f-smallest m-y-15"
            >
              <p class="f-case-upper">{{ translations.notIdentified }}</p>
              <span class="f-gotham-book"> {{ translations.notIdentifiedText }}</span>
              <bb-link :to="{ name: AccountRouteName.AccountVerification }" class="color-mint">
                {{ translations.notIdentifiedClickHere }}
              </bb-link>
            </bb-box>

            <p class="f-small f-medium m-t-20 color-navy">{{ translations.accountsListAndLimits }}</p>
            <bb-row class="gutter-20">
              <bb-col v-for="account in computedAccountLimits" :key="account.id" md="12">
                <limit-box
                  :accountId="account.accountId"
                  :accountNumber="account.iban"
                  :dailyRawLimit="account.dailyRawLimit"
                  :dailyRemainingLimit="account.dailyRemainingLimit"
                  :monthlyRawLimit="account.monthlyRawLimit"
                  :monthlyRemainingLimit="account.monthlyRemainingLimit"
                  :maximumAccountLimit="account.maximumLimit"
                  :isRestricted="account.isRestricted"
                  :isSeized="account.isSeized"
                  @accountLimitsChanged="refreshAccountsLimits"
                  class="m-y-10"
                />
              </bb-col>
            </bb-row>
            <div v-if="!featureFlags.enableAccountsEditLimits" class="f-small f-color-gray m-t-20">
              <p>{{ translations.listTitle }}</p>
              <ul class="accounts-limits__list">
                <li class="m-t-5 m-l-5">
                  <a v-if="filePath" class="color-mint f-gotham-book" :href="filePath" target="_blank" rel="external">{{ translations.fillForm }}</a>
                </li>
                <li class="m-t-5 m-l-5 f-gotham-book">
                  <span v-sanitize.basic="translations.sendFile" />
                </li>
              </ul>
            </div>
          </bb-box>
          <bb-banner
            visible
            permanent
            type="info"
            class="color-gray"
          >
            <span v-sanitize.basic="translations.infoText" />
          </bb-banner>
        </bb-col>
      </bb-row>
    </bb-container>
  </layout>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import Layout from './Layout'
import api from '../api'
import LimitBox from '../components/LimitBox.vue'
import { useAccountStore } from '@account/store/accountStore'
import { useRootStore } from '@/store/root'
import { AccountStatusCode } from '@bigbank/dc-common/clients/http/account/account.enums'
import { AccountRouteName } from '@account/const'
import { LimitPeriodType } from '@bigbank/dc-common/clients/http/account/modules/limits/limits.interfaces'

export default {
  name: 'accounts-limits',
  components: {
    Layout,
    LimitBox
  },
  data () {
    return {
      loading: true,
      accountsLimits: [],
      AccountRouteName
    }
  },
  computed: {
    ...mapState(useRootStore, ['username', 'language', 'featureFlags']),
    ...mapState(useAccountStore, ['details', 'verificationData']),
    identificationCode () {
      return this.details?.identificationCode
    },
    translations () {
      return {
        title: this.$pgettext('EDB_limits', 'Transfer limits'),
        accountsListAndLimits: this.$pgettext('EDB_limits', 'Accounts list and limits:'),
        listTitle: this.$pgettext('EDB_limits', 'If you wish to update current account transfer limits, please:'),
        fillForm: this.$pgettext('EDB_limits', 'Fill in the form'),
        sendFile: this.$gettextInterpolate(this.$pgettext('EDB_limits', 'Send the digitally signed file to <a class="color-mint" href="mailto:%{email}">%{email}</a>'), {
          email: this.$pgettext('EDB_limits', 'limiidid@bigbank.ee')
        }),
        infoText: this.$gettextInterpolate(this.$pgettext('EDB_limits', 'If you wish to have a copy of your current account contract, wish to end the service or close the account, please contact Bigbank customer support via email <a class="f-color-mint" href="mailto:%{email}">%{email}</a> or call <strong class="f-color-mint">%{phone}</strong>'), {
          email: this.$pgettext('EDB_limits', 'infoEmail'),
          phone: this.$pgettext('EDB_limits', 'infoPhone')
        }),
        fileName: this.$pgettext('EDB_limits', 'Limits change form.pdf'),
        notIdentified: this.$pgettext('EDB_limits', 'Customer is not identified'),
        notIdentifiedText: this.$pgettext('EDB_limits', 'Everyone who has not done Face to Face identification, has a monthly spending limit of €15,000. This includes internet bank transfers. To learn more'),
        notIdentifiedClickHere: this.$pgettext('EDB_limits', 'click here')
      }
    },
    filePath () {
      switch (this.language) {
        case 'et':
          return './../assets/Limits change form EE.pdf'
        case 'ru':
          return './../assets/Limits change form RU.pdf'
        default:
          return './../assets/Limits change form EN.pdf'
      }
    },
    computedAccountLimits () {
      return this.accountsLimits.map(account => {
        const dailyLimit = this.getLimit(account.limits, LimitPeriodType.Daily)
        const monthlyLimit = this.getLimit(account.limits, LimitPeriodType.Monthly)
        const maximumLimit = this.getLimit(account.limits, LimitPeriodType.Maximum)

        return {
          accountId: account.id,
          iban: account.iban,
          dailyRawLimit: dailyLimit?.rawLimit,
          dailyRemainingLimit: dailyLimit?.remainingLimit,
          monthlyRawLimit: monthlyLimit?.rawLimit,
          monthlyRemainingLimit: monthlyLimit?.remainingLimit,
          maximumLimit: maximumLimit?.rawLimit,
          isRestricted: this.isRestricted(account.statusCode),
          isSeized: this.isSeized(account.statusCode)
        }
      })
    },
    isCustomerNotF2fIdentifiedAndAdditionalLimitsEnabled () {
      return !this.verificationData.isF2fIdentified && this.featureFlags.enableUnverifiedCustomerAdditionalLimits
    }
  },
  async created () {
    this.getDetails()
    this.loadAccountLimits()
  },
  methods: {
    ...mapActions(useAccountStore, ['getDetails']),
    async loadAccountLimits () {
      this.accountsLimits = await api.getAccountsLimits() || []
      this.loading = false
    },
    getLimit (limits, limitType) {
      return limits.find(limit => limit.limitPeriodType === limitType)
    },
    isRestricted (statusCode) {
      return [AccountStatusCode.Blocked, AccountStatusCode.Frozen].includes(statusCode)
    },
    isSeized (statusCode) {
      return [AccountStatusCode.Arrested].includes(statusCode)
    },
    async refreshAccountsLimits () {
      this.loading = true
      this.accountsLimits = await api.getAccountsLimits() || []
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.accounts-limits__list {
  list-style: inside;
}
</style>
