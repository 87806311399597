<template>
  <signing-modal-wrapper
    :visible="visible"
    @close="$emit('close', $event)"
    :showLoader="isLoading">
    <template slot="heading"><translate>{{translations.title}}</translate></template>
    <p v-if="showErrorMessage" class="error">
      <signing-error-messages
        :code="errorCode"
        :error="error"
        type="idcard"
        :visible="showErrorMessage"
        :data="errorData"
        @close="closeModal"
      />
    </p>
  </signing-modal-wrapper>
</template>

<script>
import SigningModalWrapper from '@/components/signing/SigningModalWrapper'
import SigningErrorMessages from '@/components/signing/SigningErrorMessages'
import CaptureApmErrorMixin from '@/mixins/captureApmErrorMixin'
import * as webeid from '@web-eid/web-eid-library/dist/umd/web-eid'

export default {
  name: 'id-card-signing',
  components: {
    SigningModalWrapper,
    SigningErrorMessages
  },
  mixins: [CaptureApmErrorMixin],
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    lang: {
      type: String,
      required: true
    },
    startSigning: {
      required: true
    },
    completeSigning: {
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      showModal: false,
      errorCode: null,
      errorData: null,
      error: null
    }
  },
  computed: {
    translations () {
      return {
        title: this.$pgettext('signing_form_header_idcard', 'Signature with Id Card'),
        loading: this.$pgettext('loading', 'Loading please wait...')
      }
    },
    showErrorMessage () {
      return !!this.errorCode
    }
  },
  methods: {
    async initiateSigning () {
      try {
        this.isLoading = true
        this.errorCode = null
        this.error = null

        await this.sign()
      } catch (error) {
        this.error = error
        this.captureApmError(error)
        this.isLoading = false
        this.errorCode = error.errCode || error.err_code || error.code || error.type
      }
    },
    async sign () {
      try {
        const {
          certificate: certificateBase64,
          supportedSignatureAlgorithms
        } = await webeid.getSigningCertificate({ lang: this.lang })

        const { hashAlgorithm, hashBase64 } = await this.startSigning(
          certificateBase64,
          supportedSignatureAlgorithms.map((algorithmParams) => algorithmParams.hashFunction)
        )
        const { signature: signatureBase64 } = await webeid.sign(
          certificateBase64,
          hashBase64,
          hashAlgorithm,
          { lang: this.lang }
        )

        await this.validateSignature(signatureBase64)
      } catch (err) {
        this.captureApmError(err)
        this.isLoading = false

        if (err.name === 'UserCancelledError') {
          this.$emit('close')
        }

        this.errorCode = err.code ?? 'DEFAULT'
        this.error = err
      }
    },
    async validateSignature (signatureBase64) {
      try {
        const response = await this.completeSigning(signatureBase64)
        const { status, state } = response

        switch (status) {
          case 'COMPLETED_OK':
            this.isLoading = false
            this.$emit('signingSuccessful', response)
            break
          case 'COMPLETED_FAILURE':
            throw new Error(state)
        }
      } catch (error) {
        this.captureApmError(error)
        this.isLoading = false
        this.errorCode = error.errCode || error.err_code || error.message || 'INTERNAL_ERROR'
        this.error = error
        this.$emit('signingError', error)
      }
    },
    closeModal () {
      this.showModal = false
      this.errorCode = null
      this.error = null
      this.$emit('close')
    }
  },
  watch: {
    visible (isVisible) {
      if (!isVisible) {
        return
      }
      this.initiateSigning()
    }
  },
  mounted () {
    if (this.visible) {
      this.initiateSigning()
    }
  }
}
</script>
