<template>
   <fancy-hero-header
    @scroll="(value) => scrolledPercentage = value"
    :color="isIndebted ? 'red-70' : 'mint'"
  >
    <div class="hero-header" :class="{ 'hero-header--debt': isIndebted }">
      <div v-if="showNoContracts" class="hero-header__no-contracts-container align-center p-l-25 p-r-25">
        <no-contracts v-bind="noContractsData"/>
      </div>
      <progress-wheel
        v-else-if="!isCreditCardFlavour"
        flavor="client"
        :value="progressValue"
        class="m-s-a hero-header__indicator"
        :track-color="isIndebted ? 'red-30' : 'green-50'"
        :style="progressIndicatorStyleOverrides"
        :inDebt="isIndebted"
      >
        <div></div>
        <div class="indicator-display">
            <p v-if="areAllContractsClosed" class="loan-repaid-label"><translate>You have repaid your loan</translate></p>
            <template v-else>
                <p class="uppercase color-white remaining-amount-label">{{ amountLabel }}</p>
                <p class="remaining-amount m-b-0 color-white">
                  <span class="outstanding-amount">
                    <span class="amount" :name="top ? top.name : 'amount'">
                      <span
                          :class="scaledDownFontSize"
                      >{{ String(outstandingAmountTweened).split(this.decimalSeparator)[0] }}</span>
                      <span class="remaining-amount-comma-digits"
                            :class="scaledDownFontSize"
                            v-if="outstandingAmountTweened.includes(this.decimalSeparator)"
                      >{{decimalSeparator}}{{ outstandingAmountTweened.split(this.decimalSeparator)[1] }}</span>
                    </span>
                  </span>
                </p>
            </template>
        </div>
      </progress-wheel>
      <div class="hero-header__labels credit-card-labels" v-if="isCreditCardFlavour && activeContractsCount > 0">
        <div class="hero-header__label">
          <div class="hero-header__label-info-big">{{leftSide.label}}</div>
          <div class="hero-header__label-value-big" data-testid="credit-card-available-limit">{{leftSide.value}}</div>
        </div>
        <div class="hero-header__label">
          <div class="hero-header__label-info">{{rightSide.label}}</div>
          <div class="hero-header__label-value" data-testid="credit-card-reservations">{{rightSide.value}}</div>
        </div>
      </div>
      <div class="hero-header__labels" v-else-if="!showNoContracts">
          <div v-for="(opt, i) in labels" class="hero-header__label loan-label" :key="i" :name="opt.name">
              <div class="hero-header__label-value">{{opt.value}}</div>
              <div class="hero-header__label-info">{{opt.label}}</div>
          </div>
      </div>
      <div v-if="enablePrematurePaymentText && featureFlags.showPrematurePaymentText && activeContractsCount > 0" class="premature-text-container">
        <div class="premature-payment-text" v-sanitize.basic="translations.prematurePaymentText" />
      </div>
    </div>
    <sticky-navbar :color="isIndebted ? 'red-70' : 'green-90'" :visible="scrolledPercentage > 55">
      <template v-if="activeContractsCount > 0">
        <p class="uppercase">{{ amountLabel }}</p>
        <p class="outstanding-amount-value">{{ formattedOutstandingAmount }}</p>
      </template>
    </sticky-navbar>
  </fancy-hero-header>
</template>

<script>
import { formatMoneyWithCurrency, formatInterest, getDecimalSeparator } from '@/plugins/numformat'
import '@bigbank/interface-components/dist/svg/ui/menu'
import ProgressWheel from './ProgressWheel'
import gsap from 'gsap'
import { mapState } from 'pinia'
import StickyNavbar from '@/components/StickyNavbar.vue'
import FancyHeroHeader from '@/components/FancyHeroHeader.vue'
import NoContracts from '@/components/hero-header/NoContracts.vue'
import { useRootStore } from '../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'hero-header',
  components: { ProgressWheel, StickyNavbar, FancyHeroHeader, NoContracts },
  data () {
    return {
      outstandingAmountTweenedRaw: 0,
      scrolledPercentage: 0
    }
  },
  props: {
    currency: {
      type: String,
      required: true
    },
    contracts: {
      type: Array,
      required: true
    },
    top: {
      type: Object,
      required: false
    },
    leftSide: {
      type: Object,
      required: false
    },
    rightSide: {
      type: Object,
      required: false
    },
    enablePrematurePaymentText: {
      type: Boolean,
      required: false,
      default: false
    },
    flavour: {
      type: String,
      default: 'loan'
    }
  },
  computed: {
    ...mapState(useRootStore, ['username', 'locale']),
    ...mapState(useLoanStore, ['isInDebt', 'isClosed', 'isPromiseToPayShown', 'featureFlags', 'promiseToPay']),
    translations () {
      return {
        prematurePaymentText: this.$pgettext('LOAN_HERO_HEADER', 'PREMATURE_PAYMENT_TEXT')
      }
    },
    showNoContracts () {
      return this.contracts.length === 0
    },
    nonTerminatedActiveContracts () {
      return this.contracts.filter(c => !c.isTerminated && !c.isClosed)
    },
    outstandingAmount () {
      if (this.top) {
        return this.top.value
      } else if (this.isIndebted) {
        return this.nonTerminatedActiveContracts.reduce((acc, contract) => acc + contract.totalDebtAmount, 0)
      }
      return this.nonTerminatedActiveContracts.reduce((acc, contract) => acc + contract.remainingPrincipal, 0)
    },
    formattedOutstandingAmount () {
      return formatMoneyWithCurrency(this.outstandingAmount, this.currency, this.locale)
    },
    totalPaymentAmount () {
      return this.nonTerminatedActiveContracts.reduce((acc, contract) => acc + contract.totalPaymentAmount, 0)
    },
    totalMadePayments () {
      return this.nonTerminatedActiveContracts.reduce((acc, contract) => acc + contract.madePayments, 0)
    },
    totalNumberOfPayments () {
      return this.nonTerminatedActiveContracts.reduce((acc, contract) => acc + contract.numberOfPayments, 0)
    },
    totalMonthlyPayment () {
      return this.nonTerminatedActiveContracts.reduce((acc, contract) => acc + contract.nextPaymentAmount, 0)
    },
    totalPromiseToPayPayment () {
      const { paymentAmount, receivedAmount } = this.promiseToPay?.nextPayment
      const amountToPay = paymentAmount - receivedAmount

      return amountToPay < 0 ? 0 : amountToPay
    },
    interestRate () {
      return this.contracts[0].interestRate
    },
    isIndebted () {
      return this.isInDebt(this.contracts)
    },
    areAllContractsClosed () {
      return this.isClosed(this.contracts)
    },
    progressIndicatorStyleOverrides () {
      return `opacity: ${1 - this.scrolledPercentage / 100}`
    },
    outstandingAmountTweened () {
      const outstandingAmount = this.outstandingAmountTweenedRaw
        ? Math.round(this.outstandingAmountTweenedRaw * 100) / 100
        : this.outstandingAmount

      return formatMoneyWithCurrency(outstandingAmount, this.currency, this.locale)
    },
    progressValue () {
      if (this.areAllContractsClosed) {
        return 100
      } else if (this.top) {
        return this.top.progress
      }
      return this.totalNumberOfPayments ? this.totalMadePayments / this.totalNumberOfPayments * 100 : undefined
    },
    scaledDownFontSize () {
      return [{ 'digits--smaller': (this.outstandingAmountTweenedRaw ? this.outstandingAmountTweenedRaw : this.outstandingAmount) >= 10000 }]
    },
    activeContractsCount () {
      return (this.contracts || []).filter(c => !c.isClosed).length
    },
    amountLabel () {
      if (this.top) {
        return this.top.label
      } else if (this.isIndebted) {
        return this.$gettext('Total In Debt')
      } else {
        return this.$gettext('Remaining Principal')
      }
    },
    decimalSeparator () {
      return getDecimalSeparator(this.locale)
    },
    noContractsData () {
      return {
        username: this.username,
        translations: {
          description: this.$pgettext('dashboard_header', 'You currently have no contracts with us. Take a look at our offers')
        }
      }
    },
    labels () {
      const labels = []

      if (this.leftSide) {
        labels.push(this.leftSide)
      } else {
        labels.push({
          value: this.activeContractsCount,
          label: this.$gettext('Loans In Total')
        })
      }

      if (this.rightSide) {
        labels.push(this.rightSide)
      } else if (this.isPromiseToPayShown(this.contracts)) {
        labels.push({
          value: formatMoneyWithCurrency(this.totalPromiseToPayPayment, this.currency, this.locale),
          label: this.$gettext('Total monthly payment')
        })
      } else {
        labels.push({
          value: formatMoneyWithCurrency(this.totalMonthlyPayment, this.currency, this.locale),
          label: this.$gettext('Total monthly payment')
        })
      }

      return labels
    },
    isCreditCardFlavour () {
      return this.flavour === 'credit-card'
    }
  },
  watch: {
    outstandingAmount (newValue) {
      gsap.to(this.$data, {
        duration: 2,
        outstandingAmountTweenedRaw: newValue,
        onComplete () {
          this.outstandingAmountTweenedRaw = 0
        }
      })
    }
  }
}
</script>

<style lang="scss">
.progress-wheel {
  width: 166px !important;
  height: 166px !important;

  @media (min-width: $desktop-view-breaking-point-wide) {
    width: 205px !important;
    height: 205px !important;
  }
}

.outstanding-amount-value {
  font-size: $default-font-size;
  font-family: $gotham-bold;
}
</style>

<style lang="scss" scoped>
  .hero-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    width: 100%;
    max-width: 850px;
    font-family: $gotham-bold;
    z-index: 6;
    will-change: top, height;
    overflow: hidden;
    padding-bottom: 0;
    margin: auto;

    &--debt {
      background-color: $red-70;
    }

    &__no-contracts-container {
      padding-top: 20px;

      @media (min-width: $desktop-view-breaking-point) {
        padding-top: 60px;
      }
    }

    .premature-text-container {
      margin: 22px 15px 0;

      @media (min-width: map_get($grid-breakpoints, md)) {
        margin: 60px auto 0;
      }

      .premature-payment-text {
        margin: 0 auto;
        color: $white;
        font-family: $gotham-medium;
        font-size: $font-size-smallest;
        font-weight: 300;
        letter-spacing: 0.09px;
        line-height: 14px;
        width: 85%;
        text-align: center;

        ::v-deep a {
          color: $white;
        }

        ::v-deep p:not(:last-child) {
          margin-bottom: 14px;
        }
      }
    }

    .indicator-display {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      .loan-repaid-label {
        text-align: center;
        padding: 30px 30px 50px;

        @media (max-width: $mobile-view-breaking-point) {
          padding-bottom: 20px;
        }
      }

      .remaining-amount-label {
        text-align: center;
        font-size: $font-size-smallest;
        padding-bottom: 5px;

        @media (max-width: $mobile-view-breaking-point) {
          padding-bottom: 10px;
          font-size: $tiniest-font-size;
          font-family: $gotham-medium;
        }
      }

      .remaining-amount {
        .amount {
          white-space: nowrap;
          font-size: $h3-size;

          @media (min-width: $desktop-view-breaking-point-wide) {
            font-size: $h2-size;
          }
        }

        .remaining-amount-comma-digits {
          font-size: $h3-size;

          &.digits--smaller {
            font-size: $h4-size;
            transition: font-size 0.2s linear;
          }

          &.line--break {
            display: block;
            text-align: center;
            padding-top: 2px;
          }

          @media (max-width: $mobile-view-breaking-point) {
            font-size: $h4-size;
          }
        }
      }

      .outstanding-amount {
        letter-spacing: -2.5px;
      }
    }

    &__indicator {
      margin: 23px auto 0;
      pointer-events: none;
    }

    &__labels {
      min-height: 40px;
      display: flex;
      justify-content: center;
      margin-top: -30px;

      @media (min-width: $desktop-view-breaking-point-wide) {
        margin-top: -60px;
        padding-bottom: 10px;
      }
    }

    &__label.loan-label {
      text-align: center;
      width: 50%;
      margin-bottom: 0;

      &:nth-child(1) {
        margin-right: 30px;

        @media (min-width: $desktop-view-breaking-point-wide) {
          margin-right: 100px;
        }
      }

      &:nth-child(2) {
        margin-left: 30px;

        @media (min-width: $desktop-view-breaking-point-wide) {
          margin-left: 100px;
        }
      }
    }

    &__labels.credit-card-labels {
      flex-direction: column;
      align-items: center;
      margin-top: 80px;

      @media (max-width: $mobile-view-breaking-point) {
        margin-top: 40px;
      }
    }

    &__label {
      text-align: center;
      margin-bottom: 30px;

      @media (max-width: $mobile-view-breaking-point) {
        margin-bottom: 10px;

        & + .hero-header__label {
          margin-bottom: 0;
        }
      }

      &-value {
        font-size: $h3-size;

        @media (max-width: $mobile-view-breaking-point) {
          font-size: $h4-size;
        }
      }

      &-info {
        text-transform: uppercase;
        font-size: $font-size-tiny;

        @media (max-width: $mobile-view-breaking-point) {
          font-size: $tiniest-font-size;
        }
      }

      &-value-big {
        font-size: $h2-size;

        @media (max-width: $mobile-view-breaking-point) {
          font-size: $h3-size;
        }
      }

      &-info-big {
        text-transform: uppercase;
        font-size: $font-size-smallest;

        @media (max-width: $mobile-view-breaking-point) {
          font-size: $font-size-tiny;
        }
      }
    }

    .digits--smaller {
      transition: font-size 0.2s linear;
    }
  }

</style>
