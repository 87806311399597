<template>
  <div>
    <bb-modal
      visible
      full-mobile
      unsetOverflow
      size="x2"
      @close="onModalClosed"
      flavor="card"
    >
      <h5 class="text-center color-blue f-default f-gotham-medium">{{ computedTranslations.transactionDetails }}</h5>
      <div class="desktop-view">
        <dl class="m-t-20" v-if="transaction">
          <template v-for="(fieldName) in beneficiaryAndRemitterFieldsDesktop">
            <template v-if="mapped[fieldName]" >
              <dt :key="`${fieldName}-label`">{{ computedTranslations[fieldName] }}</dt>
              <dd :key="`${fieldName}-value`" :data-testid="`transaction-${fieldName}`">{{ mapped[fieldName] }}</dd>
            </template>
          </template>
          <dt class="bg-gray-20 f-gotham-bold">{{ computedTranslations.amount }}</dt>
          <dd :class="{ [mapped.amount.css]: true, 'bg-gray-20': true, 'f-gotham-bold': true }" data-testid="transaction-amount">
            <span>{{ mapped.amount.prefix }}{{ mapped.amount.value.formatted }}</span>
          </dd>
          <dt class="bg-gray-20 f-gotham-bold">{{ computedTranslations.paymentDateAndTime }}</dt>
          <dd class="bg-gray-20 f-gotham-bold" data-testid="transaction-createdAt">{{ mapped.createdAt }}</dd>
          <dt>{{ computedTranslations.paymentDescription }}</dt>
          <dd data-testid="transaction-description">{{ transaction.description }} {{ transaction.contractNumber }}</dd>
          <template v-if="transaction.referenceNumber">
            <dt>{{ computedTranslations.bankReferenceNo }}</dt>
            <dd data-testid="transaction-referenceNumber">{{ transaction.referenceNumber }}</dd>
          </template>
          <dt>{{ computedTranslations.status }}</dt>
          <dd data-testid="transaction-status">{{ mapped.status }}</dd>
          <template v-if="isCreditCard">
            <dt>{{ computedTranslations.transactionNumber }}</dt>
            <dd>{{ mapped.id }}</dd>
          </template>
        </dl>
        <div v-else class="loading-spinner">
          <bb-spinner :size="120" color="blue"/>
        </div>
      </div>
      <div class="mobile-view">
        <dl class="m-t-20 m-b-25" v-if="transaction">
          <div :class="{ [mapped.amount.css]: true, 'amount-wrapper': true }">
            <span class="amount" data-testid="transaction-mobile-amount">{{ mapped.amount.prefix }}{{ mapped.amount.value.base }}</span>
            <span class="cents-and-currency" data-testid="transaction-mobile-cents">,{{ mapped.amount.value.cents }}</span>
          </div>
          <dt>{{ computedTranslations.paymentDateAndTime }}</dt>
          <dd data-testid="transaction-mobile--createdAt">{{ mapped.createdAt }}</dd>
          <template v-for="(fieldName) in beneficiaryAndRemitterFieldsMobile.slice(0, 2)">
            <template v-if="mapped[fieldName]" >
              <dt :key="`${fieldName}-label`">{{ computedTranslations[fieldName] }}</dt>
              <dd :key="`${fieldName}-value`" :data-testid="`transaction-mobile-${fieldName}`">{{ mapped[fieldName] }}</dd>
            </template>
          </template>
          <dt>{{ computedTranslations.paymentDescription }}</dt>
          <dd data-testid="transaction-mobile-description">{{ transaction.description }} {{ transaction.contractNumber }}</dd>
          <bb-button
            :label="computedTranslations.additionalDetails"
            @click="isSpoilerVisible = !isSpoilerVisible"
            v-if="!isSpoilerVisible"
            size="sm"
            display="link"
            class="f-small f-gotham-book m-b-15"
            no-underline
            :icon-after="!isSpoilerVisible ? 'ui-chevron-down' : 'ui-chevron-up'"
            color="gray"
            name="show-additional-transaction-details"
          />
          <div v-if="isSpoilerVisible">
            <dt>{{ computedTranslations.status }}</dt>
            <dd data-testid="transaction-status">{{ mapped.status }}</dd>
            <template v-for="(fieldName) in beneficiaryAndRemitterFieldsMobile.slice(2, 4)">
              <template v-if="mapped[fieldName]" >
                <dt :key="`${fieldName}-label`">{{ computedTranslations[fieldName] }}</dt>
                <dd :data-testid="`transaction-mobile-${fieldName}`" :key="`${fieldName}-value`">{{ mapped[fieldName] }}</dd>
              </template>
            </template>
            <template v-if="transaction.referenceNumber">
              <dt>{{ computedTranslations.bankReferenceNo }}</dt>
              <dd data-testid="transaction-mobile-referenceNumber">{{ transaction.referenceNumber }}</dd>
            </template>
            <template v-if="isCreditCard">
              <dt>{{ computedTranslations.transactionNumber }}</dt>
              <dd>{{ mapped.id }}</dd>
            </template>
          </div>
        </dl>
        <div v-else class="loading-spinner">
          <bb-spinner :size="120" color="blue"/>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-md-end flex-wrap flex-md-nowrap">
          <div v-if="showDownloadAccountStatement" class="d-flex justify-content-center justify-content-md-end w-100 m-b-20 m-b-md-0">
            <bb-dropdown
              autosize
              hoverable
              :popper-opts=" { placement: ($screen.md || $screen.sm) ? 'bottom' : 'top' } "
              :disabled="isDownloadButtonDisabled"
            >
              <template #trigger>
                <bb-button
                  inverted
                  color="blue"
                  class="button-download-file"
                  :disabled="isDownloadButtonDisabled"
                  :label="computedTranslations.download"
                  icon-after="ui-menu-down"
                  icon-after-fixed
                />
              </template>
              <bb-dropdown-item
                v-for="(type, index) in downloadableFileTypes"
                @click="onDownloadButtonClicked(type)"
                :key="index"
                :label="type"
              />
            </bb-dropdown>
          </div>
          <bb-button
            v-if="showMakePaymentButton"
            v-bind="makePaymentButton"
            @click="onMakeNewPayment"
          />
          <template v-if="showCloseButton">
            <template v-if="$screen.md">
              <bb-button
                :label="computedTranslations.close"
                name="close-transaction-details"
                size="md"
                @click="onModalClosed"
              />
            </template>
            <template v-else>
              <bb-button
                :label="computedTranslations.close"
                name="close-transaction-details"
                display="block"
                corner="sharp"
                size="lg"
                @click="onModalClosed"
              />
            </template>
          </template>
        </div>
      </template>
    </bb-modal>
  </div>
</template>

<script>
import '@bigbank/interface-components/dist/svg/illustration/credit-card'
import '@bigbank/interface-components/dist/svg/illustration/virtual-card'
import '@bigbank/interface-components/dist/svg/ui/chevron-down.svg'
import { isNull } from 'lodash'
import { mapState } from 'pinia'
import dayjs from 'dayjs'
import { getDateFormat } from '@/plugins/dateFormatters'
import { MappedDirectionCode, TransactionType } from '@bigbank/dc-common/enums/loan.enums'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { AccountStatementFileType } from '@bigbank/dc-common/enums/credit-card.enums'
import { currencyCodeToSymbol } from '@/plugins/currencyFormatters'
import { useRootStore } from '../../store/root'
import { AccountRouteName } from '@/modules/account/const'

const translationKeys = [
  'account',
  'beneficiary',
  'beneficiaryAccount',
  'remitter',
  'remitterAccount',
  'amount',
  'paymentDateAndTime',
  'paymentDescription',
  'bankReferenceNo',
  'status',
  'transaction',
  'reservation'
]

export default {
  name: 'transaction-details-modal',
  data () {
    return {
      isSpoilerVisible: false
    }
  },
  props: {
    flavor: {
      type: String,
      default: 'credit-card',
      validator: (value) => ['credit-card', 'account'].includes(value)
    },
    onCloseRedirectUrl: {
      default: null
    },
    transaction: {
      type: Object,
      default: null
    },
    isDownloadButtonDisabled: {
      type: Boolean,
      default: false
    },
    isDownloadRunning: {
      type: Boolean,
      default: false
    },
    translations: {
      type: Object,
      required: true,
      validator: (val) => translationKeys.every((key) => key in val)
    },
    customerName: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'featureFlags']),
    computedTranslations () {
      return {
        close: this.$pgettext('transactions_modal', 'Close'),
        download: this.$pgettext('transactions_modal', 'Download'),
        transactionDetails: this.$pgettext('transactions_modal', 'Transaction details'),
        additionalDetails: this.$pgettext('transactions_modal', 'Additional details'),
        transactionNumber: this.$pgettext('transactions_modal', 'Transaction number'),
        makeNewPayment: this.$pgettext('transactions_modal', 'Make new payment'),
        ...this.translations
      }
    },
    canDownloadASICE () {
      return this.featureFlags.enableDownloadAccountStatementInDetailsASICE
    },
    canDownloadPDF () {
      return this.featureFlags.enableDownloadAccountStatementInDetailsPDF
    },
    hasDownloadableFileType () {
      return this.canDownloadASICE || this.canDownloadPDF
    },
    showDownloadAccountStatement () {
      return this.featureFlags.enableDownloadAccountStatementInDetails && this.hasDownloadableFileType
    },
    downloadableFileTypes () {
      const options = []
      if (this.canDownloadPDF) {
        options.push(AccountStatementFileType.PDF)
      }
      if (this.canDownloadASICE) {
        options.push(AccountStatementFileType.ASICE)
      }

      return options
    },
    beneficiaryAndRemitterFieldsDesktop () {
      return ['remitter', 'remitterAccount', 'beneficiary', 'beneficiaryAccount']
    },
    beneficiaryAndRemitterFieldsMobile () {
      return ['beneficiary', 'beneficiaryAccount', 'remitter', 'remitterAccount']
    },
    isCreditCard () {
      return this.flavor === 'credit-card'
    },
    showMakePaymentButton () {
      return !this.isCreditCard && this.featureFlags.enableAccountStatementNewPayment
    },
    showCloseButton () {
      return !this.showMakePaymentButton
    },
    makePaymentButton () {
      return {
        size: this.$screen.md ? 'md' : 'lg',
        label: this.computedTranslations.makeNewPayment,
        name: 'make-new-payment',
        style: 'white-space: nowrap;',
        display: this.$screen.md ? undefined : 'block',
        corner: this.$screen.md ? undefined : 'sharp'
      }
    },
    mapped () {
      const beneficiaryRemitterMap = {
        [MappedDirectionCode.IN]: {
          beneficiary: this.customerName,
          beneficiaryAccount: this.transaction.accountIban,
          remitter: this.transaction.counterpartyName,
          remitterAccount: this.transaction.counterpartyIban
        },
        [MappedDirectionCode.OUT]: {
          beneficiary: this.transaction.counterpartyName,
          beneficiaryAccount: this.transaction.counterpartyIban,
          remitter: this.customerName,
          remitterAccount: this.transaction.accountIban
        }
      }

      const formattedAmount = formatMoneyWithCurrency(this.transaction?.amount ?? 0, 'EUR', this.locale)

      return {
        amount: {
          css: (() => {
            if (this.transaction?.type === TransactionType.Reservation) {
              return 'amount-color-reservation'
            }

            switch (this.transaction?.transactionDirectionCode) {
              case MappedDirectionCode.OUT: return 'amount-color-outcome'
              case MappedDirectionCode.IN: return 'amount-color-income'
              default: return ''
            }
          })(),
          prefix: (() => {
            switch (this.transaction?.transactionDirectionCode) {
              case MappedDirectionCode.OUT: return '-'
              case MappedDirectionCode.IN: return '+'
              default: return ''
            }
          })(), // TODO: Check by code
          value: {
            original: this.transaction?.amount,
            formatted: formattedAmount,
            base: formattedAmount.split(',')[0] ?? 'N/A',
            cents: formattedAmount.split(',')[1] ?? '00'
          },
          currency: {
            code: this.transaction?.currencyCode ?? 'N/A',
            symbol: currencyCodeToSymbol(this.transaction?.currencyCode, 'N/A')
          }
        },
        status: {
          [TransactionType.Reservation]: this.computedTranslations.reservation,
          [TransactionType.Transaction]: this.computedTranslations.transaction
        }[this.transaction?.type] ?? '-',
        customerName: this.customerName ?? 'N/A',
        currencyCode: this.transaction?.currencyCode ?? 'N/A',
        createdAt: dayjs(this.transaction?.createdAt ?? '').format(`${getDateFormat()} | HH:mm`),
        ...beneficiaryRemitterMap[this.transaction?.transactionDirectionCode],
        id: this.transaction?.id
      }
    }
  },
  methods: {
    onDownloadButtonClicked (fileType) {
      this.$emit('download', fileType)
    },
    onModalClosed () {
      return isNull(this.onCloseRedirectUrl)
        ? this.$emit('close')
        : this.$router.push({ name: this.onCloseRedirectUrl })
    },
    onMakeNewPayment () {
      this.$router.push({
        name: AccountRouteName.Payments,
        query: { transactionId: this.transaction.id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.loading-spinner {
  width: 120px;
  margin: 50px auto;
}

.desktop-view { display: none; }
.amount-color-reservation { color: $gray-50; }
.amount-color-income { color: $mint; }
.amount-color-outcome { color: $navy; }

.button-dropdown-file-format {
  width: 118px;
  margin-right: 10px;
  padding-left: 2rem !important;
}

@media (max-width: $mobile-view-breaking-point) {
  .amount {
    font-size: $h1-size-mobile;
    font-family: $gotham-medium;
  }
  .amount-wrapper { margin-bottom: 16px; }

  dt {
    font-size: $font-size-tiny;
    height: 16px;
    line-height: 16px;
    color: $gray-70;
  }

  dd {
    font-family: $gotham-book;
    font-size: $font-size-small;
    line-height: 18px;
    margin-bottom: 16px;
    color: $gray;
  }
}

@media (min-width: $mobile-view-breaking-point) {
  .desktop-view { display: block; }
  .mobile-view { display: none; }
  .amount-color-reservation { color: $navy; }

  .button-download-file {
    margin-right: 15px;
  }

  dl {
    display: flex;
    flex-flow: row wrap;
    color: $navy;
    font-size: $font-size-smallest;
    font-family: $gotham-book;
  }

  dt {
    flex-basis: 35%;
    text-align: right;
    padding: 6px 4px;
    padding-right: 20px;
    line-height: 30px;
  }

  dd {
    flex-basis: 55%;
    flex-grow: 1;
    padding: 6px 4px;
    font-weight: 400;
    line-height: 30px;
    font-family: $gotham-medium;
  }
}
</style>
