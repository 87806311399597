<script>
import api from '@account/api'
import { AuthMethod } from '@bigbank/dc-common/config'
import SignWithEparaksts from '@loan/components/signing/SignWithEparaksts.vue'

export default {
  name: 'eparaksts-signing-account',
  extends: SignWithEparaksts,
  props: {
    contract: {
      required: false
    }
  },
  methods: {
    startSigning () {
      const redirectUrl = window.location.origin + this.$route.fullPath
      return api.initSigning(AuthMethod.EPARAKSTS, this.signingRequestId, { redirectUrl })
    },
    completeSigning () {
      return api.completeSigning(AuthMethod.EPARAKSTS, this.signingRequestId)
    }
  }
}
</script>
